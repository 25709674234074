const actions = {
	ADD_BUILDER_MODULE: "ADD_BUILDER_MODULE",
	ADD_BUILDER_RULE: "ADD_BUILDER_RULE",
	ADD_JOURNEY_MEETING_FAILURE: "ADD_JOURNEY_MEETING_FAILURE",
	ADD_JOURNEY_MEETING: "ADD_JOURNEY_MEETING",
	ADD_OR_UPDATE_BUILDER_MODULE_FAILURE: "ADD_OR_UPDATE_BUILDER_MODULE_FAILURE",
	ADD_OR_UPDATE_BUILDER_MODULE_SUCCESS: "ADD_OR_UPDATE_BUILDER_MODULE_SUCCESS",
	ADD_OR_UPDATE_RULE_FAILURE: "ADD_OR_UPDATE_RULE_FAILURE",
	ADD_OR_UPDATE_RULE_SUCCESS: "ADD_OR_UPDATE_RULE_SUCCESS",
	APPROVE_JOURNEY_CONFIRM_MODAL_VISIBLE:
		"APPROVE_JOURNEY_CONFIRM_MODAL_VISIBLE",
	APPROVE_JOURNEY_FAILURE: "APPROVE_JOURNEY_FAILURE",
	APPROVE_JOURNEY_SUCCESS: "APPROVE_JOURNEY_SUCCESS",
	APPROVE_JOURNEY: "APPROVE_JOURNEY",
	CHANGE_APPROVAL_STATUS: "CHANGE_APPROVAL_STATUS",
	CHANGE_JOURNEY_MODAL_STATUS: "CHANGE_JOURNEY_MODAL_STATUS",
	CHANGE_RULE_DRAWER_STATUS: "CHANGE_RULE_DRAWER_STATUS",
	CHECK_ASSIGNED_JOURNEY: "CHECK_ASSIGNED_JOURNEY",
	COMPANY_DETAILS: "COMPANY_DETAILS",
	CREATE_NEW_JOURNEY_FAILURE: "CREATE_NEW_JOURNEY_FAILURE",
	CREATE_NEW_JOURNEY_SUCCESS: "CREATE_NEW_JOURNEY_SUCCESS",
	CREATE_NEW_JOURNEY: "CREATE_NEW_JOURNEY",
	DELETE_JOURNEY_FAILURE: "DELETE_JOURNEY_FAILURE",
	DELETE_JOURNEY_SUCCESS: "DELETE_JOURNEY_SUCCESS",
	DELETE_JOURNEY: "DELETE_JOURNEY",
	DELETE_MODULES_FAILURE: "DELETE_MODULES_FAILURE",
	DELETE_MODULES_SUCCESS: "DELETE_MODULES_SUCCESS",
	DELETE_MODULES: "DELETE_MODULES",
	DUPLICATE_JOURNEY_FAILURE: "DUPLICATE_JOURNEY_FAILURE",
	DUPLICATE_JOURNEY_SUCCESS: "DUPLICATE_JOURNEY_SUCCESS",
	DUPLICATE_JOURNEY: "DUPLICATE_JOURNEY",
	FETCH_ASSETS_FAILURE: "FETCH_ASSETS_FAILURE",
	FETCH_ASSETS_SUCCESS: "FETCH_ASSETS_SUCCESS",
	FETCH_ASSETS: "FETCH_ASSETS",
	FETCH_GMAIL_ADDRESS: "FETCH_GMAIL_ADDRESS",
	FETCH_INT_SLACK_USERS: "FETCH_INT_SLACK_USERS",
	FETCH_JOURNEY_BUILDER_DETAILS: "FETCH_JOURNEY_BUILDER_DETAILS",
	FETCH_JOURNEY_MEETINGS_FAILURE: "FETCH_JOURNEY_MEETINGS_FAILURE",
	FETCH_JOURNEY_MEETINGS_SUCCESS: "FETCH_JOURNEY_MEETINGS_SUCCESS",
	FETCH_JOURNEY_MEETINGS: "FETCH_JOURNEY_MEETINGS",
	FETCH_JOURNEY_TASKS_FAILURE: "FETCH_JOURNEY_TASKS_FAILURE",
	FETCH_JOURNEY_TASKS_SUCCESS: "FETCH_JOURNEY_TASKS_SUCCESS",
	FETCH_JOURNEY_TASKS: "FETCH_JOURNEY_TASKS",
	FETCH_JOURNEY_USERS_FAILURE: "FETCH_JOURNEY_USERS_FAILURE",
	FETCH_JOURNEY_USERS_SUCCESS: "FETCH_JOURNEY_USERS_SUCCESS",
	FETCH_JOURNEY_USERS: "FETCH_JOURNEY_USERS",
	FETCH_JOURNEYS_ADMIN_TEMPLATE_FAILURE:
		"FETCH_JOURNEYS_ADMIN_TEMPLATE_FAILURE",
	FETCH_JOURNEYS_ADMIN_TEMPLATE_SUCCESS:
		"FETCH_JOURNEYS_ADMIN_TEMPLATE_SUCCESS",
	FETCH_JOURNEYS_ADMIN_TEMPLATE: "FETCH_JOURNEYS_ADMIN_TEMPLATE",
	FETCH_JOURNEYS_BUILDER_DETAILS_FAILURE:
		"FETCH_JOURNEYS_BUILDER_DETAILS_FAILURE",
	FETCH_JOURNEYS_BUILDER_DETAILS_SUCCESS:
		"FETCH_JOURNEYS_BUILDER_DETAILS_SUCCESS",
	FETCH_JOURNEYS_FAILURE: "FETCH_JOURNEYS_FAILURE",
	FETCH_JOURNEYS_SUCCESS: "FETCH_JOURNEYS_SUCCESS",
	FETCH_JOURNEYS: "FETCH_JOURNEYS",
	FETCH_MERGES: "FETCH_MERGES",
	FETCH_ROLES_FAILURE: "FETCH_ROLES_FAILURE",
	FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
	FETCH_ROLES: "FETCH_ROLES",
	FETCH_SLACK_ASSET: "FETCH_SLACK_ASSET",
	FETCH_SLACK: "FETCH_SLACK",
	FETCH_TICKETING_MERGE: "FETCH_TICKETING_MERGE",
	FETCH_TICKETING_USERS: "FETCH_TICKETING_USERS",
	FETCH_TICKETING: "FETCH_TICKETING",
	FETCH_TIMEZONE_SUCCESS: "FETCH_TIMEZONE_SUCCESS",
	FETCH_TIMEZONE: "FETCH_TIMEZONE",
	FETCH_RULES_LIST: "FETCH_RULES_LIST",
	GET_COMPANY_DETAILS: "GET_COMPANY_DETAILS",
	JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE: "JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE",
	ON_SEARCH_JOURNEYS: "ON_SEARCH_JOURNEYS",
	REMOVE_BUILDER_RULE: "REMOVE_BUILDER_RULE",
	REMOVE_BUILDER_RULE_FAILURE: "REMOVE_BUILDER_RULE_FAILURE",
	REMOVE_BUILDER_RULE_SUCCESS: "REMOVE_BUILDER_RULE_SUCCESS",
	SET_ADMIN_JOURNEY_DETAILS: "SET_ADMIN_JOURNEY_DETAILS",
	SET_ADMIN_LIST_PAGE_SIZE: "SET_ADMIN_LIST_PAGE_SIZE",
	SET_ASSET_CREATED_MODULE: "SET_ASSET_CREATED_MODULE",
	SET_ASSET_PAGE: "SET_ASSET_PAGE",
	SET_ASSET_SEARCH: "SET_ASSET_SEARCH",
	SET_CREATED_MODULE: "SET_CREATED_MODULE",
	SET_EDIT_JOURNEY_MODAL: "SET_EDIT_JOURNEY_MODAL",
	SET_GMAIL_ADDRESS: "SET_GMAIL_ADDRESS",
	SET_INT_SLACK_USERS: "SET_INT_SLACK_USERS",
	SET_JOURNEY_ACTIVE_LIST: "SET_JOURNEY_ACTIVE_LIST",
	SET_JOURNEY_ADMIN_TEMPLATE_PAGINATION:
		"SET_JOURNEY_ADMIN_TEMPLATE_PAGINATION",
	SET_JOURNEY_ADMIN_TEMPLATE_SORT_VALUES:
		"SET_JOURNEY_ADMIN_TEMPLATE_SORT_VALUES",
	SET_JOURNEY_HEADER_SKELETON: "SET_JOURNEY_HEADER_SKELETON",
	SET_JOURNEY_PAGINATION: "SET_JOURNEY_PAGINATION",
	SET_JOURNEY_SORT_VALUES: "SET_JOURNEY_SORT_VALUES",
	SET_LIST_MODULE_DRAWER_VISIBLE: "SET_LIST_MODULE_DRAWER_VISIBLE",
	SET_LIST_PAGE_SIZE: "SET_LIST_PAGE_SIZE",
	SET_MEETINGS_SEARCH: "SET_MEETINGS_SEARCH",
	SET_MODULE_DELETE_DATA: "SET_MODULE_DELETE_DATA",
	SET_REMINDER_DEFAULTS: "SET_REMINDER_DEFAULTS",
	SET_REMINDER_OPTIONS: "SET_REMINDER_OPTIONS",
	SET_SLACK_ASSETS: "SET_SLACK_ASSETS",
	SET_SLACK_USERS: "SET_SLACK_USERS",
	SET_TASK_PAGE: "SET_TASK_PAGE",
	SET_TASK_SEARCH: "SET_TASK_SEARCH",
	SET_TICKETING_ASSETS: "SET_TICKETING_ASSETS",
	SET_TICKETING_MERGE: "SET_TICKETING_MERGE",
	SET_TICKETING_USERS: "SET_TICKETING_USERS",
	SET_RULES_LIST: "SET_RULES_LIST",
	UPDATE_ADD_MODULE_DRAWER_INFO: "UPDATE_ADD_MODULE_DRAWER_INFO",
	UPDATE_BUILDER_EVENTS: "UPDATE_BUILDER_EVENTS",
	UPDATE_BUILDER_JSON: "UPDATE_BUILDER_JSON",
	UPDATE_BUILDER_MODULE: "UPDATE_BUILDER_MODULE",
	UPDATE_BUILDER_RULE: "UPDATE_BUILDER_RULE",
	UPDATE_CURRENT_JOURNEY_ID: "UPDATE_CURRENT_JOURNEY_ID",
	UPDATE_CURRENT_MODULE_ACTION: "UPDATE_CURRENT_MODULE_ACTION",
	UPDATE_CURRENT_NODE_DATA: "UPDATE_CURRENT_NODE_DATA",
	UPDATE_JOURNEY_DATA: "UPDATE_JOURNEY_DATA",
	UPDATE_JOURNEY_FAILURE: "UPDATE_JOURNEY_FAILURE",
	UPDATE_JOURNEY_MEETING_FAILURE: "UPDATE_JOURNEY_MEETING_FAILURE",
	UPDATE_JOURNEY_MEETING: "UPDATE_JOURNEY_MEETING",
	UPDATE_JOURNEY_RULE_DATA: "UPDATE_JOURNEY_RULE_DATA",
	UPDATE_JOURNEY_SUCCESS: "UPDATE_JOURNEY_SUCCESS",
	UPDATE_JOURNEY: "UPDATE_JOURNEY",
	UPDATE_JOURNEYS_SELECT_ALL: "UPDATE_JOURNEYS_SELECT_ALL",
	UPDATE_MEETINGS_REMINDER: "UPDATE_MEETINGS_REMINDER",
	UPDATE_MODULE_DELETE_CONFIRM: "UPDATE_MODULE_DELETE_CONFIRM",
	UPDATE_OR_CREATE_RULE: "UPDATE_OR_CREATE_RULE",
	UPDATE_SELECTED_ASSET: "UPDATE_SELECTED_ASSET",
	UPDATE_SELECTED_JOURNEY: "UPDATE_SELECTED_JOURNEY",
	UPDATE_TASK_DUES: "UPDATE_TASK_DUES",
	UPDATE_TRANSFORM: "UPDATE_TRANSFORM",
	USER_MERGES: "USER_MERGES",
	FETCH_OUTLOOK_ADDRESS: "FETCH_OUTLOOK_ADDRESS",
	SET_OUTLOOK_ADDRESS: "SET_OUTLOOK_ADDRESS",
	FETCH_MICROSOFT_USERS: "FETCH_MICROSOFT_USERS",
	SET_MICROSOFT_TEAM_USERS: "SET_MICROSOFT_TEAM_USERS",
	AUTO_ADD_MODAL_VISIBLE: "AUTO_ADD_MODAL_VISIBLE",
	UPDATE_AUTO_ADD_CONDITION: "UPDATE_AUTO_ADD_CONDITION",
	GET_AUTO_ADD_MATCHED_EMPLOYEES: "GET_AUTO_ADD_MATCHED_EMPLOYEES",
	FETCH_CUSTOM_RELATIONS_LIST: "FETCH_CUSTOM_RELATIONS_LIST",
	SET_CUSTOM_RELATIONS_LIST: "SET_CUSTOM_RELATIONS_LIST",
	FETCH_AUTO_ADD_FIELDS: "FETCH_AUTO_ADD_FIELDS",
	SET_AUTO_ADD_FIELDS: "SET_AUTO_ADD_FIELDS",
	FETCH_AUTO_ADD_CONDITION: "FETCH_AUTO_ADD_CONDITION",
	SET_AUTO_ADD_CONDITION: "SET_AUTO_ADD_CONDITION",
	SET_FILTERED_EMPLOYEES_DETAILS: "SET_FILTERED_EMPLOYEES_DETAILS",
	SET_IS_FILTERING_EMPLOYEES: "SET_IS_FILTERING_EMPLOYEES",
	SET_QUERY_BUILDER_LOADER: "SET_QUERY_BUILDER_LOADER",
	SET_AUTO_ADD_FIELDS_LOADER: "SET_AUTO_ADD_FIELDS_LOADER",
	SET_AUTO_ADD_QUERY_LOADER: "SET_AUTO_ADD_QUERY_LOADER",
	SET_CURRENT_ACTIVE_TAB: "SET_CURRENT_ACTIVE_TAB",
	SET_AUTO_ADD_MODAL_MODE: "SET_AUTO_ADD_MODAL_MODE",
	FETCH_JOURNEY_ANALYTICS: "FETCH_JOURNEY_ANALYTICS",
	FETCH_ANALYTICS_STATS: "FETCH_ANALYTICS_STATS",
	FETCH_ANALYTICS_INTERACTIONS: "FETCH_ANALYTICS_INTERACTIONS",
	SET_JOURNEY_ANALYTICS: "SET_JOURNEY_ANALYTICS",
	SET_JOURNEY_ANALYTICS_LOADER: "SET_JOURNEY_ANALYTICS_LOADER",
	SET_ANALYTICS_FILTER: "SET_ANALYTICS_FILTER",
	SET_USER_CHANNELS: "SET_USER_CHANNELS",
	FETCH_EVENT_COMPARISON_DATA: "FETCH_EVENT_COMPARISON_DATA",
	SET_EVENT_ANALYTICS_COMPARISON_DATA: "SET_EVENT_ANALYTICS_COMPARISON_DATA",
	CREATE_AUTO_ADD_CONDITION: "CREATE_AUTO_ADD_CONDITION",
	FETCH_AUTO_ADD_CONDITIONS: "FETCH_AUTO_ADD_CONDITIONS",
	SET_AUTO_ADD_CONDITIONS: "SET_AUTO_ADD_CONDITIONS",
	SET_IS_FETCHING_AUTO_ADD_CONDITIONS: "SET_IS_FETCHING_AUTO_ADD_CONDITIONS",
	FETCH_JOURNEY_STATUS: "FETCH_JOURNEY_STATUS",
	SET_APPROVAL_STATUS: "SET_APPROVAL_STATUS",
	FETCH_TAGS: "FETCH_TAGS",
	SET_JOURNEY_TAGS: "SET_JOURNEY_TAGS",
	SET_ALL_TAGS: "SET_ALL_TAGS",
	REMOVE_JOURNEY_TAG: "REMOVE_JOURNEY_TAG",
	ADD_JOURNEY_TAG: "ADD_JOURNEY_TAG",
	ADD_JOURNEY_TAG_SUCCESS: "ADD_JOURNEY_TAG_SUCCESS",
	REMOVE_JOURNEY_TAG_SUCCESS: "REMOVE_JOURNEY_TAG_SUCCESS"
};
export default actions;
