import { cloneDeep } from "lodash";
import actions from "./actions";
import {
	approveText,
	chartData,
	defaultSortKey,
	defaultSortOrder,
	employeeRole,
	moduleActionTypeCreate,
	unApproveText,
	JOURNEY_TAB,
	dateFormatDefault,
	journeyStatuses,
	approvingText,
	unapprovingText,
} from "components/constants";
import moment from "moment";
import { getApprovalStatus, isValid } from "redux/helper";

const initState = {
	addEmailModuleVisible: false,
	addModuleLoader: false,
	addModuleType: null,
	addModuleVisible: false,
	addRuleLoader: false,
	addSmsModuleVisible: false,
	afterDueDates: [],
	approvalStatus: null,
	assetPage: 1,
	assets: [],
	assetSearchKey: "",
	assetSearchLoader: false,
	assetsInitialListLoader: false,
	assetsListLoader: false,
	beforeDueDates: [],
	copyJourneyContent: {},
	createdModule: {},
	currentJourneyID: null,
	currentNodeParentRuleID: null,
	CurrentPage: 1,
	currentRule: null,
	deleteModule: {},
	deleteModuleLoader: false,
	editJourneyVisible: false,
	editModuleData: {},
	editModuleType: null,
	EmailsPrefix: [],
	gmail_address: [],
	intSlackUsers: [],
	isAssigned: false,
	isFetchingRoles: false,
	isJourneyModalVisible: false,
	isRuleDrawerVisible: false,
	isSelectAllJourneys: false,
	isShowUpArrow: false,
	journeyActiveList: "",
	journeyApprovalModalVisible: false,
	journeyBuilderData: {},
	journeyBuilderInitialLoader: false,
	journeyBuilderJSON: cloneDeep(chartData),
	journeyLoader: false,
	journeyModalLoader: false,
	journeys: [],
	journeysInitialLoader: false,
	journeyTemplate: [],
	journeyTemplateCurrentPage: 1,
	journeyTemplateHeaderLoader: false,
	journeyTemplateLoader: false,
	journeyTemplatePerSize: 10,
	journeyTemplateSortKey: "asc",
	journeyTemplateSortOrder: "name",
	listPerPage: 10,
	listSearchKey: "",
	listSortKey: defaultSortKey,
	listSortValue: defaultSortOrder,
	meetingReminders: [],
	moduleActionType: moduleActionTypeCreate,
	moduleDeleteConfirmVisible: false,
	moduleOptionsVisible: false,
	nextRuleID: -1,
	parentRuleID: -1,
	prevRuleID: -1,
	reminders: {},
	roles: [],
	ruleActionType: null,
	selectedAsset: {},
	selectedJourneyKeys: [],
	sendVia: "email",
	slackAssets: [],
	slackUsers: [],
	taskDues: [],
	taskInitialLoader: false,
	taskListLoader: false,
	taskPage: 1,
	tasks: [],
	taskSearchKey: "",
	taskSearchLoader: false,
	ticketingAssets: [],
	ticketingMerges: [],
	ticketingUsers: [],
	timeZone: [],
	totalJourneys: null,
	totalJourneyTemplate: 0,
	userMerges: [],
	users: [],
	usersListLoader: false,
	outlookAddress: [],
	microsoftTeamUsers: [],
	autoAddModalVisible: false,
	filteredEmployees: null,
	ruleList: [],
	customRelationsList: [],
	autoAddFields: [],
	autoAddCondition: null,
	autoAddConditionList: [],
	isFetchingFilteredEmployees: false,
	queryBuilderLoader: true,
	isFetchingAutoAddFields: true,
	isFetchingAutoAddQuery: true,
	currentActiveTab: JOURNEY_TAB,
	autoAddModalMode: "autoAdd",
	journeyAnalytics: {
		stats: null,
		interactions: null,
	},
	eventAnalyticsComparisonData: {},
	isLoadingStats: true,
	isLoadingInteractions: true,
	analyticsFilter: {
		date_gteq: moment().subtract(6, "days").format(dateFormatDefault),
		date_lt: moment().add(1, "days").format(dateFormatDefault),
		employee_ids: null,
		query: { sql: "(1=1)", json: '{"rules":[],"combinator":"and"}' },
	},
	userChannels: [],
	isFetchingAutoAddConditions: false,
	journeyTags: [],
	allTags: [],
};

export default function journeyReducer(state = initState, action) {
	switch (action.type) {
		case actions.ADD_BUILDER_MODULE:
			return { ...state, addModuleLoader: true };
		case actions.ADD_BUILDER_RULE: {
			return { ...state, addRuleLoader: true };
		}
		case actions.UPDATE_BUILDER_RULE: {
			return { ...state, addRuleLoader: true };
		}
		case actions.REMOVE_BUILDER_RULE: {
			return { ...state, deleteModuleLoader: true };
		}
		case actions.REMOVE_BUILDER_RULE_SUCCESS: {
			return { ...state, deleteModuleLoader: false };
		}
		case actions.REMOVE_BUILDER_RULE_FAILURE: {
			return { ...state, deleteModuleLoader: false };
		}
		case actions.ADD_JOURNEY_MEETING_FAILURE:
			return { ...state, addModuleLoader: false };
		case actions.ADD_JOURNEY_MEETING:
			return { ...state, addModuleLoader: true };
		case actions.ADD_OR_UPDATE_BUILDER_MODULE_FAILURE:
			return { ...state, addModuleLoader: false };
		case actions.ADD_OR_UPDATE_BUILDER_MODULE_SUCCESS:
			return {
				...state,
				addEmailModuleVisible: false,
				addModuleLoader: false,
				journeyBuilderData: {
					...state.journeyBuilderData,
					modified_at: action.payload.modified_at,
				},
			};
		case actions.ADD_OR_UPDATE_RULE_FAILURE:
			return { ...state, addRuleLoader: false };
		case actions.ADD_OR_UPDATE_RULE_SUCCESS:
			return {
				...state,
				addRuleLoader: false,
				isRuleDrawerVisible: false,
				currentRule: null,
				ruleActionType: null,
				journeyBuilderData: {
					...state.journeyBuilderData,
					modified_at: action.payload.updated_at,
				},
			};
		case actions.APPROVE_JOURNEY_CONFIRM_MODAL_VISIBLE:
			return { ...state, journeyApprovalModalVisible: action.payload };
		case actions.APPROVE_JOURNEY_FAILURE:
			return { ...state, journeyModalLoader: false };
		case actions.APPROVE_JOURNEY_SUCCESS:
			return {
				...state,
				journeyModalLoader: false,
				journeyApprovalModalVisible: false,
				journeyBuilderData: {
					...state.journeyBuilderData,
					status: `${action.payload.status}`,
				},
			};
		case actions.APPROVE_JOURNEY:
			return { ...state, journeyModalLoader: true };
		case actions.CHANGE_APPROVAL_STATUS:
			return {
				...state,
				approvalStatus: journeyStatuses[state.journeyBuilderData.status],
			};
		case actions.CHANGE_JOURNEY_MODAL_STATUS:
			return { ...state, isJourneyModalVisible: action.payload };
		case actions.CHANGE_RULE_DRAWER_STATUS: {
			return {
				...state,
				isRuleDrawerVisible: action.payload,
				currentRule: action.payload ? state.currentRule : null,
				ruleActionType: action.payload ? state.ruleActionType : null,
			};
		}
		case actions.CHECK_ASSIGNED_JOURNEY: {
			return { ...state, isAssigned: action.payload };
		}
		case actions.COMPANY_DETAILS: {
			const { company } = action.payload.user;
			const prefixes = company.sendgrid_prefixs.filter(
				(prefix) => prefix.email_name !== null,
			);
			return {
				...state,
				EmailsPrefix: prefixes,
				sendgridDomainStatus: company.sendgrid_domain_status,
				companyDomainDetails: company.sendgrid_details,
			};
		}
		case actions.CREATE_NEW_JOURNEY_FAILURE:
			return { ...state, journeyModalLoader: false };
		case actions.CREATE_NEW_JOURNEY_SUCCESS:
			return {
				...state,
				journeyModalLoader: false,
				isJourneyModalVisible: false,
				journeyActiveList: "journey",
			};
		case actions.CREATE_NEW_JOURNEY:
			return { ...state, journeyModalLoader: true };
		case actions.DELETE_JOURNEY_FAILURE:
			return { ...state, journeyModalLoader: false };
		case actions.DELETE_JOURNEY_SUCCESS:
			return {
				...state,
				journeyModalLoader: false,
				deleteModalVisible: false,
				journeyActiveList: action.payload,
			};
		case actions.DELETE_JOURNEY:
			return { ...state, journeyModalLoader: true };
		case actions.DELETE_MODULES_FAILURE:
			return { ...state, deleteModuleLoader: false };
		case actions.DELETE_MODULES_SUCCESS:
			return {
				...state,
				moduleDeleteConfirmVisible: false,
				deleteModuleLoader: false,
				journeyBuilderData: {
					...state.journeyBuilderData,
					modified_at: action.payload.modified_at,
				},
			};
		case actions.DELETE_MODULES:
			return { ...state, deleteModuleLoader: true };
		case actions.DUPLICATE_JOURNEY_FAILURE: {
			return { ...state, journeyModalLoader: false };
		}
		case actions.DUPLICATE_JOURNEY_SUCCESS: {
			return {
				...state,
				journeyModalLoader: false,
				isJourneyModalVisible: false,
				journeyActiveList: "journey",
			};
		}
		case actions.DUPLICATE_JOURNEY: {
			return { ...state, journeyModalLoader: true };
		}
		case actions.FETCH_ASSETS_FAILURE:
			return {
				...state,
				assetsListLoader: false,
				assetsInitialListLoader: false,
				assetSearchLoader: false,
			};
		case actions.FETCH_ASSETS_SUCCESS: {
			const assetsList =
				state.assetPage === 1
					? action.payload
					: state.assets.concat(action.payload);
			return {
				...state,
				assets: assetsList,
				assetsListLoader: false,
				assetsInitialListLoader: false,
				assetSearchLoader: false,
			};
		}
		case actions.FETCH_ASSETS:
			return {
				...state,
				assetsListLoader: true,
				assetsInitialListLoader: action.initialFetch,
				assets:
					isValid(state.assetSearchKey) && state.assetPage === 1
						? []
						: state.assets,
				assetSearchLoader: action.isSearch,
			};
		case actions.FETCH_JOURNEY_BUILDER_DETAILS:
			return { ...state, journeyBuilderInitialLoader: true };
		case actions.FETCH_JOURNEY_TASKS_FAILURE:
			return {
				...state,
				taskInitialLoader: false,
				taskListLoader: false,
				taskSearchLoader: false,
			};
		case actions.FETCH_JOURNEY_TASKS_SUCCESS: {
			const taskList =
				state.taskPage === 1
					? action.payload.tasks
					: state.tasks.concat(action.payload.tasks);
			return {
				...state,
				tasks: taskList,
				sendVia:
					action.payload.send_via == null ? "email" : action.payload.send_via,
				taskInitialLoader: false,
				taskListLoader: false,
				taskSearchLoader: false,
			};
		}
		case actions.FETCH_JOURNEY_TASKS:
			return {
				...state,
				taskInitialLoader: action.initialFetch,
				taskListLoader: true,
				tasks:
					isValid(state.taskSearchKey) && state.taskPage === 1
						? []
						: state.tasks,
				taskSearchLoader: action.isSearch,
			};
		case actions.FETCH_JOURNEY_USERS_FAILURE:
			return { ...state, usersListLoader: false };
		case actions.FETCH_JOURNEY_USERS_SUCCESS:
			return { ...state, users: action.payload, usersListLoader: false };
		case actions.FETCH_JOURNEY_USERS:
			return { ...state, usersListLoader: true };
		case actions.FETCH_JOURNEYS_ADMIN_TEMPLATE_FAILURE: {
			return {
				...state,
				journeyTemplateLoader: false,
				journeyTemplateHeaderLoader: false,
			};
		}
		case actions.FETCH_JOURNEYS_ADMIN_TEMPLATE_SUCCESS: {
			return {
				...state,
				journeyTemplateLoader: false,
				journeyTemplateHeaderLoader: false,
				journeyTemplate: action.payload.journeys,
				totalJourneyTemplate: action.payload.total_records,
			};
		}
		case actions.FETCH_JOURNEYS_ADMIN_TEMPLATE: {
			return {
				...state,
				journeyTemplateLoader: true,
				journeyTemplateHeaderLoader: true,
			};
		}
		case actions.FETCH_JOURNEYS_BUILDER_DETAILS_FAILURE:
			return { ...state, journeyBuilderInitialLoader: false };
		case actions.FETCH_JOURNEYS_BUILDER_DETAILS_SUCCESS:
			return {
				...state,
				journeyBuilderData: action.payload,
				journeyBuilderInitialLoader: false,
				approvalStatus: getApprovalStatus(action.payload),
			};
		case actions.FETCH_JOURNEYS_FAILURE:
			return { ...state, journeyLoader: false, journeysInitialLoader: false };
		case actions.FETCH_JOURNEYS_SUCCESS:
			return {
				...state,
				journeyLoader: false,
				journeysInitialLoader: false,
				journeys: action.payload.journeys,
				totalJourneys: action.payload.total_records,
			};
		case actions.FETCH_JOURNEYS:
			return {
				...state,
				journeyLoader: true,
				journeysInitialLoader: action.isInitialFetch,
			};
		case actions.FETCH_ROLES_FAILURE:
			return { ...state, isFetchingRoles: false };
		case actions.FETCH_ROLES_SUCCESS: {
			const roles = action.payload;
			roles.unshift(employeeRole);
			return { ...state, roles: roles, isFetchingRoles: false };
		}
		case actions.FETCH_ROLES:
			return { ...state, isFetchingRoles: true };
		case actions.FETCH_TIMEZONE_SUCCESS:
			return { ...state, timeZone: action.payload };
		case actions.JOURNEY_DELETE_CONFIRM_MODAL_VISIBLE:
			return { ...state, deleteModalVisible: action.payload };
		case actions.ON_SEARCH_JOURNEYS:
			return { ...state, listSearchKey: action.payload };
		case actions.SET_ADMIN_JOURNEY_DETAILS: {
			return { ...state, copyJourneyContent: action.payload };
		}
		case actions.SET_ADMIN_LIST_PAGE_SIZE: {
			return { ...state, journeyTemplatePerSize: action.payload };
		}
		case actions.SET_ASSET_PAGE:
			return { ...state, assetPage: action.payload };
		case actions.SET_ASSET_SEARCH:
			return { ...state, assetSearchKey: action.payload };
		case actions.SET_CREATED_MODULE:
			return { ...state, createdModule: action.payload };
		case actions.SET_EDIT_JOURNEY_MODAL:
			return { ...state, editJourneyVisible: action.payload };
		case actions.SET_GMAIL_ADDRESS:
			return { ...state, gmail_address: action.payload };
		case actions.SET_INT_SLACK_USERS:
			return { ...state, intSlackUsers: action.payload };
		case actions.SET_JOURNEY_ACTIVE_LIST: {
			return { ...state, journeyActiveList: action.payload };
		}
		case actions.SET_JOURNEY_ADMIN_TEMPLATE_PAGINATION: {
			return { ...state, journeyTemplateCurrentPage: action.payload };
		}
		case actions.SET_JOURNEY_ADMIN_TEMPLATE_SORT_VALUES: {
			return {
				...state,
				journeyTemplateSortKey: action.sortKey,
				journeyTemplateSortValue: action.sortOrder,
			};
		}
		case actions.SET_JOURNEY_HEADER_SKELETON:
			return { ...state, journeyBuilderInitialLoader: true };
		case actions.SET_JOURNEY_PAGINATION:
			return { ...state, CurrentPage: action.payload };
		case actions.SET_JOURNEY_SORT_VALUES:
			return {
				...state,
				listSortKey: action.sortKey,
				listSortValue: action.sortOrder,
			};
		case actions.SET_LIST_MODULE_DRAWER_VISIBLE: {
			return {
				...state,
				addModuleVisible: action.payload.value,
				parentRuleID: action.payload?.parentRuleID || state.parentRuleID,
				selectedAsset: !action.payload && {},
			};
		}
		case actions.SET_LIST_PAGE_SIZE:
			return { ...state, listPerPage: action.payload };
		case actions.SET_MODULE_DELETE_DATA:
			return { ...state, deleteModule: action.payload };
		case actions.SET_REMINDER_DEFAULTS:
			return { ...state, reminders: action.payload };
		case actions.SET_REMINDER_OPTIONS:
			return {
				...state,
				beforeDueDates: action.beforeDueDates,
				afterDueDates: action.afterDueDates,
			};
		case actions.SET_SLACK_ASSETS:
			return { ...state, slackAssets: action.payload };
		case actions.SET_SLACK_USERS:
			return { ...state, slackUsers: action.payload };
		case actions.SET_TASK_PAGE:
			return { ...state, taskPage: action.payload };
		case actions.SET_TASK_SEARCH:
			return { ...state, taskSearchKey: action.payload };
		case actions.SET_TICKETING_ASSETS:
			return { ...state, ticketingAssets: action.payload };
		case actions.SET_TICKETING_MERGE:
			return {
				...state,
				ticketingMerges: action.payload,
			};
		case actions.SET_TICKETING_USERS:
			return { ...state, ticketingUsers: action.payload };
		case actions.UPDATE_ADD_MODULE_DRAWER_INFO: {
			return {
				...state,
				addEmailModuleVisible: action.payload,
				addModuleType: action.moduleType || null,
				assetPage: action.page,
				parentRuleID: action?.parentRuleID || state.parentRuleID,
			};
		}
		case actions.UPDATE_BUILDER_EVENTS:
			return {
				...state,
				journeyBuilderData: {
					...state.journeyBuilderData,
					events: action.payload,
				},
			};
		case actions.UPDATE_BUILDER_JSON:
			return {
				...state,
				journeyBuilderJSON: cloneDeep(action.payload.chartData),
			};
		case actions.UPDATE_JOURNEY_RULE_DATA:
			return {
				...state,
				journeyBuilderData: {
					...state.journeyBuilderData,
					rules: action.payload.rules,
				},
			};
		case actions.UPDATE_BUILDER_MODULE:
			return { ...state, addModuleLoader: true };
		case actions.UPDATE_CURRENT_JOURNEY_ID:
			return { ...state, currentJourneyID: action.payload };
		case actions.UPDATE_CURRENT_MODULE_ACTION:
			return {
				...state,
				moduleActionType: action.actionType,
				editModuleData: action.editModuleData,
				editModuleType: action.editModuleType,
			};
		case actions.UPDATE_CURRENT_NODE_DATA:
			return { ...state, currentNodeParentRuleID: action.payload };
		case actions.UPDATE_JOURNEY_DATA:
			return { ...state, journeyBuilderData: action.payload };
		case actions.UPDATE_JOURNEY_FAILURE:
			return { ...state, isUpdatingJourney: false };
		case actions.UPDATE_JOURNEY_MEETING_FAILURE:
			return { ...state, addModuleLoader: false };
		case actions.UPDATE_JOURNEY_MEETING:
			return { ...state, addModuleLoader: true };
		case actions.UPDATE_JOURNEY_SUCCESS:
			return {
				...state,
				isUpdatingJourney: false,
				journeyBuilderData: { ...state.journeyBuilderData, ...action.payload },
			};
		case actions.UPDATE_JOURNEY:
			return { ...state, isUpdatingJourney: true };
		case actions.UPDATE_JOURNEYS_SELECT_ALL:
			return { ...state, isSelectAllJourneys: action.payload };
		case actions.UPDATE_MEETINGS_REMINDER:
			return { ...state, meetingReminders: action.payload };
		case actions.UPDATE_MODULE_DELETE_CONFIRM:
			return { ...state, moduleDeleteConfirmVisible: action.payload };
		case actions.UPDATE_OR_CREATE_RULE: {
			return {
				...state,
				ruleActionType: action.payload.ruleActionType,
				currentRule: action.payload.rule,
				prevRuleID: action.payload.prevRuleID,
				nextRuleID: action.payload.nextRuleID,
			};
		}
		case actions.UPDATE_SELECTED_ASSET:
			return { ...state, selectedAsset: action.payload };
		case actions.UPDATE_SELECTED_JOURNEY:
			return { ...state, selectedJourneyKeys: action.payload };
		case actions.UPDATE_TASK_DUES:
			return { ...state, taskDues: action.payload };
		case actions.UPDATE_TRANSFORM:
			return { ...state, isShowUpArrow: action.payload };
		case actions.USER_MERGES: {
			return { ...state, userMerges: action.payload };
		}
		case actions.SET_OUTLOOK_ADDRESS:
			return { ...state, outlookAddress: action.payload };
		case actions.SET_MICROSOFT_TEAM_USERS:
			return { ...state, microsoftTeamUsers: action.payload };
		case actions.AUTO_ADD_MODAL_VISIBLE:
			return { ...state, autoAddModalVisible: action.payload };
		case actions.SET_RULES_LIST:
			return { ...state, ruleList: action.payload };
		case actions.SET_CUSTOM_RELATIONS_LIST:
			return { ...state, customRelationsList: action.payload };
		case actions.SET_AUTO_ADD_FIELDS:
			return { ...state, autoAddFields: action.payload };
		case actions.SET_AUTO_ADD_CONDITION: {
			if (action.payload) {
				return {
					...state,
					autoAddCondition: action.payload,
					journeyBuilderData: {
						...state.journeyBuilderData,
						auto_add_condition_id: action.payload.id,
					},
				};
			} else {
				return { ...state, autoAddCondition: action.payload };
			}
		}
		case actions.SET_FILTERED_EMPLOYEES_DETAILS:
			return {
				...state,
				filteredEmployees:
					action.payload !== null
						? {
								count: action.payload.filtered_employees_count,
								ids: action.payload.filtered_employees,
						  }
						: action.payload,
			};
		case actions.SET_IS_FILTERING_EMPLOYEES:
			return { ...state, isFetchingFilteredEmployees: action.payload };
		case actions.SET_QUERY_BUILDER_LOADER:
			return { ...state, queryBuilderLoader: action.payload };
		case actions.SET_AUTO_ADD_QUERY_LOADER:
			return { ...state, isFetchingAutoAddQuery: action.payload };
		case actions.SET_AUTO_ADD_FIELDS_LOADER:
			return { ...state, isFetchingAutoAddFields: action.payload };
		case actions.SET_CURRENT_ACTIVE_TAB:
			return { ...state, currentActiveTab: action.payload };
		case actions.SET_AUTO_ADD_MODAL_MODE:
			return { ...state, autoAddModalMode: action.payload };
		case actions.SET_JOURNEY_ANALYTICS_LOADER:
			return {
				...state,
				isLoadingStats:
					action.payload.statsLoading === undefined
						? state.isLoadingStats
						: action.payload.statsLoading,
				isLoadingInteractions:
					action.payload.interactionsLoading === undefined
						? state.isLoadingInteractions
						: action.payload.interactionsLoading,
			};

		case actions.SET_JOURNEY_ANALYTICS:
			return {
				...state,
				journeyAnalytics: {
					stats: action.payload.stats || state.journeyAnalytics.stats,
					interactions:
						action.payload.interactions || state.journeyAnalytics.interactions,
				},
			};
		case actions.SET_ANALYTICS_FILTER:
			return { ...state, analyticsFilter: action.payload };
		case actions.SET_USER_CHANNELS:
			return { ...state, userChannels: action.payload };
		case actions.SET_EVENT_ANALYTICS_COMPARISON_DATA: {
			return {
				...state,
				eventAnalyticsComparisonData: {
					...state.eventAnalyticsComparisonData,
					...action.payload,
				},
			};
		}
		case actions.SET_AUTO_ADD_CONDITIONS: {
			return { ...state, autoAddConditionList: action.payload };
		}
		case actions.SET_IS_FETCHING_AUTO_ADD_CONDITIONS: {
			return { ...state, isFetchingAutoAddConditions: action.payload };
		}
		case actions.SET_APPROVAL_STATUS: {
			return { ...state, approvalStatus: action.payload };
		}
		case actions.SET_JOURNEY_TAGS: {
			return { ...state, journeyTags: action.payload }
		}
		case actions.SET_ALL_TAGS: {
			return { ...state, allTags: action.payload }
		}
		case actions.REMOVE_JOURNEY_TAG_SUCCESS: {
			return {
				...state,
				journeys: state.journeys.map(journey =>
					journey.id === action.payload.journey_id
						? { ...journey, tags: [...action.payload.tags] }
						: journey
				)
			};
		}
		case actions.ADD_JOURNEY_TAG_SUCCESS: {
			return {
				...state,
				journeys: state.journeys.map(journey =>
					journey.id === action.payload.journey_id
						? { ...journey, tags: [...action.payload.tags] }
						: journey
				)
			};
		}
		default:
			return state;
	}
}
