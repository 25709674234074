import React from "react";
import { Typography } from "antd";
import emailIcon from "assets/images/email.svg";
import smsIcon from "assets/images/message.svg";
import slackIcon from "assets/images/slackIcon.svg";
import { ReactComponent as Envelope } from "assets/images/envelope.svg";
import { ReactComponent as EnvelopeFill } from "assets/images/envelope-fill.svg";
import { ReactComponent as PaperPlane } from "assets/images/paper-plane.svg";
import variables from "styles/variables";
import { userRoutes } from "routes/privateRoutes";
import templateRoutes from "routes/templateRoutes";
import { menus, templateMenu } from "containers/Sidebar/menus";
import taskIcon from "assets/images/task.svg";
import meetingIcon from "assets/images/meeting.svg";
import assetsMessage from "assets/images/assets-messages.svg";
import assetsPage from "assets/images/assets-page.svg";
import assetsTemplate from "assets/images/assets-templates.svg";
import assetsTask from "assets/images/assets-task.svg";
import portalCustomization from "assets/images/portal-customization.svg";
import journeyIcon from "assets/images/rocket.svg";
import IntlMessages from "components/utility/intlMessages";
import trelloIcon from "assets/images/trello-logo.svg";
import microsoftTeamsIcon from "assets/images/microsoft_teams.svg";
const { Link } = Typography;

export const tableColumnSortOrder = {
	descend: "desc",
	ascend: "asc",
};
export const tableColumnSortOrderInverse = {
	desc: "descend",
	asc: "ascend",
};

export const searchTimeout = 500;
export const employeeDetailsUpdateTimeout = 1000;
export const generalTimeout = 2000;

export const dateFormat = "MMMM D, YYYY";
export const USDateFormat = "MM/DD/YYYY";

export const importFileTypes = ".csv,.xls,.xlsx";
export const imageTypes =
	".jpg, .jpeg, .jfif, .pjpeg, .pjp, .ico, .cur, .gif, .bmp, .apng, .png, .tif, .tiff, .webp";

export const emailLogoImageTypes = ".jpg, .gif, .png";

export const validateFileTypes = ["csv", "xls", "xlsx"];
export const validImageTypes = [
	"jpg",
	"jpeg",
	"jfif",
	"pjpeg",
	"pjp",
	"ico",
	"cur",
	"gif",
	"bmp",
	"apng",
	"png",
	"svg",
	"tif",
	"tiff",
	"webp",
];

export const h4 = 4;

export const defaultSortKey = "uploaded_at";

export const defaultSortOrder = "desc";

export const defaultPageSize = 10;

export const defaultPage = 1;

export const drawerWidth = 500;

export const dateTimeFormat = "MMMM D, YYYY h:mm A z";

export const emailDetails = {
	label: "journey.selectEmail",
	moduleValidation: "journey.module.emailValidation",
	createModule: "journey.createEmail",
	heading: "journey.addModule.email",
	icon: emailIcon,
	addButton: "label.add",
};

export const smsDetails = {
	label: "journey.selectSms",
	moduleValidation: "journey.module.smsValidation",
	createModule: "journey.createSms",
	heading: "journey.addModule.sms",
	icon: smsIcon,
	addButton: "label.add",
};

export const taskDetails = {
	label: "journey.selectTask",
	moduleValidation: "journey.module.taskValidation",
	createModule: "journey.createTask",
	heading: "journey.addModule.task",
	icon: taskIcon,
	addButton: "label.add",
	iconHeight: 45,
};

export const meetingDetails = {
	heading: "journey.addModule.meetings",
	icon: meetingIcon,
	addButton: "label.add",
	iconHeight: 45,
};

export const trelloDetails = {
	label: "journey.selectPamphlet",
	heading: "journey.addModule.trello",
	icon: trelloIcon,
	addButton: "label.add",
	iconHeight: 45,
	moduleValidation: "journey.module.taskValidation",
	createModule: "journey.createTrello",
};

export const slackDetails = {
	label: "journey.selectSlackAsset",
	heading: "journey.addModule.slack",
	icon: slackIcon,
	addButton: "label.add",
	iconHeight: 45,
	moduleValidation: "journey.module.slackAssetValidation",
	createModule: "journey.createSlack",
};

export const microsoftTeamDetails = {
	label: "journey.selectMicrosoftTeamAsset",
	heading: "journey.addModule.microsoftTeams",
	icon: microsoftTeamsIcon,
	addButton: "label.add",
	iconHeight: 45,
	moduleValidation: "journey.module.microsoftTeamAssetValidation",
	createModule: "journey.createMicrosoftTeam",
};

/* Journey builder constants */

export const BUILDER_RULE_CREATION_TYPE = "rule_creation";
export const BUILDER_RULE_UPDATION_TYPE = "rule_updation";
export const BUILDER_RULE_DELETION_TYPE = "rule_deletion";

export const BUILDER_EVENT_CREATION_TYPE = "event_creation";
export const BUILDER_EVENT_UPDATION_TYPE = "event_update";
export const BUILDER_EVENT_DELETION_TYPE = "event_deletion";

export const RULE_NODE_TYPE = "rule_node";
export const EVENT_NODE_TYPE = "event_node";
export const ADD_NEW_NODE_NODE_TYPE = "add_new_node_node";
export const ADD_RULE_ACTION = "add_rule";
export const ADD_EVENT_ACTION = "add_event";
export const ADD_EVENT_OR_RULE_PROMPT_ACTION = "add_event_or_rule_prompt";
export const NO_ACTION = "none";
export const ADD_NEW_NODE_NODE_HEIGHT = 30;
export const RULE_NODE_HEIGHT = 36;
export const EVENT_NODE_HEIGHT = 80;
export const ADD_NEW_NODE_NODE_WIDTH = 30;
export const RULE_NODE_WIDTH = 420;
export const EVENT_NODE_WIDTH = 150;
export const RULE_NODE_SPACE = 92;
export const EVENT_NODE_SPACE = 120;
export const ADD_NEW_NODE_NODE_SPACE = 286;
export const SPACE_BETWEEN_NODES = 40;
export const RULE_LOGIC_ON = "on";
export const RULE_LOGIC_BEFORE = "before";
export const RULE_LOGIC_AFTER = "after";

export const chartData = {
	offset: {
		x: (window.screen.width - RULE_NODE_WIDTH - 600) / 2,
		y: 0,
	},
	scale: 1,
	nodes: {},
	links: {},
	selected: {},
	hovered: {},
};

export const RULE_TRIGGER_ADDED_TO_JOURNEY = "added_to_journey";
export const RULE_TRIGGER_COMPLETION_OF_STEP = "completion_of_step";
export const RULE_TRIGGER_PREVIOUS_EVENT_COMPLETED = "previous_event_completed";

export const RULE_TRIGGER_BIRTHDAY = "birthday";
export const RULE_TRIGGER_CUSTOM_DATE = "custom_date";
export const RULE_TRIGGER_LEAVE_OF_ABSENCE_START_DATE =
	"leave_of_absence_start_date";
export const RULE_TRIGGER_RETURN_FROM_LEAVE = "return_from_leave";
export const RULE_TRIGGER_START_DATE = "start_date";
export const RULE_TRIGGER_TERMINATION_DATE = "termination_date";
export const RULE_TRIGGER_WORK_ANNIVERSARY = "work_anniversary";

export const RULE_TRIGGER_TYPE_EVENT = "event";
export const RULE_TRIGGER_TYPE_DATE = "date";

export const RULE_TRIGGER_TYPE_DATE_LIST = ["Custom Date", "Start Date"];
export const RULE_TRIGGER_TYPE_EVENT_LIST = ["Added To Journey"];
export const RULE_TRIGGER_TYPE_DATE_COMING_SOON_LIST = [
	"Birthday",
	"Leave Of Absence Start Date",
	"Return From Leave",
	"Termination Date",
	"Work Anniversay",
];
export const RULE_TRIGGER_TYPE_EVENT_COMING_SOON_LIST = [
	"Completion Of Step",
	"Previous Event Completed",
];

export const RULE_OFFSET_MINUTES = "minutes";
export const RULE_OFFSET_HOURS = "hours";
export const RULE_OFFSET_DAYS = "days";
export const RULE_OFFSET_WEEKS = "weeks";
export const RULE_OFFSET_MONTHS = "months";
export const RULE_OFFSET_YEARS = "years";

export const RULE_LOGIC_LIST = ["Before", "On", "After"];
export const RULE_OFFSET_TYPES_LIST = [
	"Minute",
	"Hour",
	"Day",
	"Week",
	"Month",
	"Year",
];

export const TAG_COMING_SOON = "Coming Soon";

export const RULE_OFFSET_YEARS_LIMIT = 5;
export const RULE_OFFSET_MONTHS_LIMIT = 60;
export const RULE_OFFSET_WEEKS_LIMIT = 100;
export const RULE_OFFSET_DAYS_LIMIT = 100;
export const RULE_OFFSET_HOURS_LIMIT = 100;
export const RULE_OFFSET_MINUTES_LIMIT = 100;

export const RULE_DATE_FORMAT = "YYYY-MM-DD";

export const RULE_ACTION_CREATE = "create";
export const RULE_ACTION_UPDATE = "update";
export const RULE_TRIGGER_TYPE_CUSTOM_FIELD = "custom_date_field_trigger";
export const RULE_TRIGGER_CUSTOM_FIELD = "custom_date_field";

export const RULE_COMPONENT_OFFSET = "Offset";
export const RULE_COMPONENT_OFFSET_TYPE = "Offset Type";
export const RULE_COMPONENT_LOGIC = "Logic";
export const RULE_COMPONENT_TRIGGER = "Trigger";
export const RULE_COMPONENT_DATE = "Date";
export const RULE_COMPONENT_TIME = "Time";

export const dayNodeSpace = 120;
export const addNodeSpace = 182;
export const moduleNodeSpace = 15;
export const spaceBetweenNodes = 30;

export const dayNodeType = "day_node";
export const addNodeType = "add_node";
export const onBoardNodeType = "on_board";
export const preBoardNodeType = "pre_board";

export const preBoardDayNode = `${preBoardNodeType}_${dayNodeType}`;
export const preBoardAddNode = `${preBoardNodeType}_${addNodeType}`;
export const onBoardDayNode = `${onBoardNodeType}_${dayNodeType}`;
export const onBoardAddNode = `${onBoardNodeType}_${addNodeType}`;

export const nodeModuleType = "module";
export const addNodeHeight = 32;
export const dayNodeHeight = 28;
export const moduleNodeHeight = 71;
export const dayNodeWidth = 150;

export const assetTypeEmail = "email";
export const assetTypeSms = "sms";
export const assetTypeTask = "task";
export const assetTypeMeeting = "meeting";
export const assetTypeTrello = "trello";
export const assetTypeTicketing = "ticketing";
export const assetTypeSlack = "slack";
export const assetTypeMicrosoftTeams = "microsoftTeams";

export const moduleCards = [
	{
		moduleImage: emailIcon,
		label: "journey.module.email",
		type: assetTypeEmail,
		description: "journey.module.email.content",
	},
	{
		moduleImage: smsIcon,
		label: "journey.addModule.sms",
		type: assetTypeSms,
		description: "journey.module.message.content",
	},
	// {
	// 	moduleImage: taskIcon,
	// 	label: "journey.addModule.task",
	// 	type: assetTypeTask,
	// 	description: "journey.module.task.content",
	// 	iconHeight: 45,
	// },
	// {
	// 	moduleImage: meetingIcon,
	// 	label: "journey.addModule.meetings",
	// 	type: assetTypeMeeting,
	// 	description: "journey.module.meetings.content",
	// 	iconHeight: 45,
	// },
	// {
	//   moduleImage: trelloIcon,
	//   label: "journey.addModule.trello",
	//   type: assetTypeTrello,
	//   description: "journey.module.trello.content",
	//   iconHeight: 45,
	// },
	// {
	//   moduleImage: slackIcon,
	//   label: "journey.addModule.slack",
	//   type: assetTypeSlack,
	//   description: "journey.module.slack.content",
	//   iconHeight: 45,
	// },
	// {
	//   moduleImage: microsoftTeamsIcon,
	//   label: "journey.addModule.microsoftTeams",
	//   type: assetTypeMicrosoftTeams,
	//   description: "journey.module.microsoftTeams.content",
	//   iconHeight: 45,
	// },
];

export const hour24TimeFormat = "HH:mm";
export const hour12TimeFormat = "h:mm A";
export const dropdownListPerPage = 20;

export const defaultColumns = [
	"email",
	"phone",
	"position",
	"first_name",
	"last_name",
	"start_date",
	"manager_name",
	"manager_email",
	"manager_phone",
	"pause",
];

export const hiddenColumns = ["slack_user_mention"];

export const moduleActionTypeCreate = "create";
export const moduleActionTypeEdit = "edit";
export const builderSkeletonData = [
	{
		shape: "default",
		style: { width: RULE_NODE_WIDTH, height: RULE_NODE_HEIGHT },
	},
	{
		shape: "default",
		style: { width: 320, height: EVENT_NODE_HEIGHT },
	},
	{
		shape: "circle",
		style: { width: 32, height: 32 },
	},
	{
		shape: "default",
		style: { width: RULE_NODE_WIDTH, height: RULE_NODE_HEIGHT },
	},
	{
		shape: "default",
		style: { width: 320, height: EVENT_NODE_HEIGHT },
	},
	{
		shape: "circle",
		style: { width: 32, height: 32 },
	},
	{
		shape: "default",
		style: { width: RULE_NODE_WIDTH, height: RULE_NODE_HEIGHT },
	},
	{
		shape: "circle",
		style: { width: 32, height: 32 },
	},
];

export const approveText = "approve";
export const approvingText = "approving";
export const unApproveText = "unapprove";
export const unApprovingText = "unapproving";

export const journeyStatuses = {
	approved: "approved",
	unapproved: "unapproved",
	approving: "approving",
	unapproving: "unapproving",
};

export const scheduleTimeMinute = 15;

export const notFoundCode = 404;

export const initialBuilderHeight = 1000;

export const h2 = 2;

export const logoURL =
	"https://s3.amazonaws.com/development.gosquadsy.com/logo.png";

// Don't ES-lint added this constant.
export const defaultEmailHTMLContent = `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
	<html
		xmlns="http://www.w3.org/1999/xhtml"
		xmlns:v="urn:schemas-microsoft-com:vml"
		xmlns:o="urn:schemas-microsoft-com:office:office"
	>
		<head>
			<!--[if gte mso 9]>
				<xml>
					<o:OfficeDocumentSettings>
						<o:AllowPNG />
						<o:PixelsPerInch>96</o:PixelsPerInch>
					</o:OfficeDocumentSettings>
				</xml>
			<![endif]-->
			<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0" />
			<meta name="x-apple-disable-message-reformatting" />
			<!--[if !mso]><!-->
			<meta http-equiv="X-UA-Compatible" content="IE=edge" />
			<!--<![endif]-->
			<title></title>

			<style type="text/css">
				@media only screen and (min-width: 920px) {
					.u-row {
						width: 900px !important;
					}
					.u-row .u-col {
						vertical-align: top;
					}

					.u-row .u-col-100 {
						width: 900px !important;
					}
				}

				@media (max-width: 920px) {
					.u-row-container {
						max-width: 100% !important;
						padding-left: 0px !important;
						padding-right: 0px !important;
					}
					.u-row .u-col {
						min-width: 320px !important;
						max-width: 100% !important;
						display: block !important;
					}
					.u-row {
						width: 100% !important;
					}
					.u-col {
						width: 100% !important;
					}
					.u-col > div {
						margin: 0 auto;
					}
				}
				body {
					margin: 0;
					padding: 0;
				}

				table,
				tr,
				td {
					vertical-align: top;
					border-collapse: collapse;
				}

				.ie-container table,
				.mso-container table {
					table-layout: fixed;
				}

				* {
					line-height: inherit;
				}

				a[x-apple-data-detectors="true"] {
					color: inherit !important;
					text-decoration: none !important;
				}

				table,
				td {
					color: #000000;
				}
			</style>
		</head>

		<body
			class="clean-body u_body"
			style="
				margin: 0;
				padding: 0;
				-webkit-text-size-adjust: 100%;
				background-color: #fff;
				color: #000000;
			"
		>
			<!--[if IE]><div class="ie-container"><![endif]-->
			<!--[if mso]><div class="mso-container"><![endif]-->
			<table
				style="
					border-collapse: collapse;
					table-layout: fixed;
					border-spacing: 0;
					mso-table-lspace: 0pt;
					mso-table-rspace: 0pt;
					vertical-align: top;
					min-width: 320px;
					margin: 0 auto;
					background-color: #fff;
					width: 100%;
				"
				cellpadding="0"
				cellspacing="0"
			>
				<tbody>
					<tr style="vertical-align: top">
						<td
							style="
								word-break: break-word;
								border-collapse: collapse !important;
								vertical-align: top;
							"
						>
							<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #fff;"><![endif]-->

							<div
								class="u-row-container"
								style="padding: 0px; background-color: transparent"
							>
								<div
									class="u-row"
									style="
										margin: 0 auto 0 0;
										min-width: 320px;
										max-width: 900px;
										overflow-wrap: break-word;
										word-wrap: break-word;
										word-break: break-word;
										background-color: transparent;
									"
								>
									<div
										style="
											border-collapse: collapse;
											display: table;
											width: 100%;
											height: 100%;
											background-color: transparent;
										"
									>
										<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="left"><table cellpadding="0" cellspacing="0" border="0" style="width:900px;"><tr style="background-color: transparent;"><![endif]-->

										<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
									</div>
								</div>
							</div>

							<div
								class="u-row-container"
								style="padding: 0px; background-color: transparent"
							>
								<div
									class="u-row"
									style="
										margin: 0 auto 0 0;
										min-width: 320px;
										max-width: 900px;
										overflow-wrap: break-word;
										word-wrap: break-word;
										word-break: break-word;
										background-color: transparent;
									"
								>
									<div
										style="
											border-collapse: collapse;
											display: table;
											width: 100%;
											height: 100%;
											background-color: transparent;
										"
									>
										<!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="left"><table cellpadding="0" cellspacing="0" border="0" style="width:900px;"><tr style="background-color: transparent;"><![endif]-->

										<!--[if (mso)|(IE)]><td align="center" width="900" style="width: 900px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
										<div
											class="u-col u-col-100"
											style="
												max-width: 320px;
												min-width: 900px;
												display: table-cell;
												vertical-align: top;
											"
										>
											<div
												style="
													height: 100%;
													width: 100% !important;
													border-radius: 0px;
													-webkit-border-radius: 0px;
													-moz-border-radius: 0px;
												"
											>
												<!--[if (!mso)&(!IE)]><!--><div
													style="
														box-sizing: border-box;
														height: 100%;
														padding: 0px;
														border-top: 0px solid transparent;
														border-left: 0px solid transparent;
														border-right: 0px solid transparent;
														border-bottom: 0px solid transparent;
														border-radius: 0px;
														-webkit-border-radius: 0px;
														-moz-border-radius: 0px;
													"
												><!--<![endif]-->
													<!--[if (!mso)&(!IE)]><!-->
												</div>
												<!--<![endif]-->
											</div>
										</div>
										<!--[if (mso)|(IE)]></td><![endif]-->
										<!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
									</div>
								</div>
							</div>

							<!--[if (mso)|(IE)]></td></tr></table><![endif]-->
						</td>
					</tr>
				</tbody>
			</table>
			<!--[if mso]></div><![endif]-->
			<!--[if IE]></div><![endif]-->
		</body>
</html>`;

export const defaultPageHtmlContent = `
<!doctype html>
	<html>

	<head>
		<meta charset="utf-8">
		<meta http-equiv="x-ua-compatible" content="ie=edge">
		<title></title>
		<meta name="description" content="">
		<meta name="viewport" content="width=device-width, initial-scale=1">


		<style type="text/css">
			a {
				color: #0000ee;
				text-decoration: underline;
			}
			
			a:hover {
				color: #0000ee;
				text-decoration: underline;
			}
			
			body,
			html {
				padding: 0;
				margin: 0
			}
			
			html {
				box-sizing: border-box
			}
			
			*,
			:after,
			:before {
				box-sizing: inherit
			}
			
			html {
				font-size: 14px;
				-ms-overflow-style: scrollbar;
				-webkit-tap-highlight-color: rgba(0, 0, 0, 0)
			}
			
			body {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 1rem;
				line-height: 1.5;
				color: #373a3c;
				background-color: #fff
			}
			
			p {
				margin: 0
			}
			
			.container {
				width: 100%;
				padding-right: 0;
				padding-left: 0;
				margin-right: auto;
				margin-left: auto
			}
			
			@media (min-width:576px) {
				.container {
					max-width: 540px
				}
			}
			
			@media (min-width:768px) {
				.container {
					max-width: 720px
				}
			}
			
			@media (min-width:992px) {
				.container {
					max-width: 960px
				}
			}
			
			@media (min-width:1200px) {
				.container {
					max-width: 1140px
				}
			}
			
			.container-fluid {
				width: 100%;
				padding-right: 0;
				padding-left: 0;
				margin-right: auto;
				margin-left: auto
			}
			
			.row {
				display: flex;
				flex-wrap: wrap
			}
			
			.no-gutters,
			.row {
				margin-right: 0;
				margin-left: 0
			}
			
			.no-gutters>.col,
			.no-gutters>[class*=col-] {
				padding-right: 0;
				padding-left: 0
			}
			
			.col,
			.col-1,
			.col-2,
			.col-3,
			.col-4,
			.col-5,
			.col-6,
			.col-7,
			.col-8,
			.col-9,
			.col-10,
			.col-11,
			.col-12,
			.col-auto,
			.col-lg,
			.col-lg-1,
			.col-lg-2,
			.col-lg-3,
			.col-lg-4,
			.col-lg-5,
			.col-lg-6,
			.col-lg-7,
			.col-lg-8,
			.col-lg-9,
			.col-lg-10,
			.col-lg-11,
			.col-lg-12,
			.col-lg-auto,
			.col-md,
			.col-md-1,
			.col-md-2,
			.col-md-3,
			.col-md-4,
			.col-md-5,
			.col-md-6,
			.col-md-7,
			.col-md-8,
			.col-md-9,
			.col-md-10,
			.col-md-11,
			.col-md-12,
			.col-md-auto,
			.col-sm,
			.col-sm-1,
			.col-sm-2,
			.col-sm-3,
			.col-sm-4,
			.col-sm-5,
			.col-sm-6,
			.col-sm-7,
			.col-sm-8,
			.col-sm-9,
			.col-sm-10,
			.col-sm-11,
			.col-sm-12,
			.col-sm-auto,
			.col-xl,
			.col-xl-1,
			.col-xl-2,
			.col-xl-3,
			.col-xl-4,
			.col-xl-5,
			.col-xl-6,
			.col-xl-7,
			.col-xl-8,
			.col-xl-9,
			.col-xl-10,
			.col-xl-11,
			.col-xl-12,
			.col-xl-auto {
				position: relative;
				width: 100%;
				padding-right: 0;
				padding-left: 0
			}
			
			.col {
				flex-basis: 0;
				flex-grow: 1;
				max-width: 100%
			}
			
			.col-auto {
				flex: 0 0 auto;
				width: auto;
				max-width: 100%
			}
			
			.col-1 {
				flex: 0 0 8.33333%;
				max-width: 8.33333%
			}
			
			.col-2 {
				flex: 0 0 16.66667%;
				max-width: 16.66667%
			}
			
			.col-3 {
				flex: 0 0 25%;
				max-width: 25%
			}
			
			.col-4 {
				flex: 0 0 33.33333%;
				max-width: 33.33333%
			}
			
			.col-5 {
				flex: 0 0 41.66667%;
				max-width: 41.66667%
			}
			
			.col-6 {
				flex: 0 0 50%;
				max-width: 50%
			}
			
			.col-7 {
				flex: 0 0 58.33333%;
				max-width: 58.33333%
			}
			
			.col-8 {
				flex: 0 0 66.66667%;
				max-width: 66.66667%
			}
			
			.col-9 {
				flex: 0 0 75%;
				max-width: 75%
			}
			
			.col-10 {
				flex: 0 0 83.33333%;
				max-width: 83.33333%
			}
			
			.col-11 {
				flex: 0 0 91.66667%;
				max-width: 91.66667%
			}
			
			.col-12 {
				flex: 0 0 100%;
				max-width: 100%
			}
			
			.order-first {
				order: -1
			}
			
			.order-last {
				order: 13
			}
			
			.order-0 {
				order: 0
			}
			
			.order-1 {
				order: 1
			}
			
			.order-2 {
				order: 2
			}
			
			.order-3 {
				order: 3
			}
			
			.order-4 {
				order: 4
			}
			
			.order-5 {
				order: 5
			}
			
			.order-6 {
				order: 6
			}
			
			.order-7 {
				order: 7
			}
			
			.order-8 {
				order: 8
			}
			
			.order-9 {
				order: 9
			}
			
			.order-10 {
				order: 10
			}
			
			.order-11 {
				order: 11
			}
			
			.order-12 {
				order: 12
			}
			
			.offset-1 {
				margin-left: 8.33333%
			}
			
			.offset-2 {
				margin-left: 16.66667%
			}
			
			.offset-3 {
				margin-left: 25%
			}
			
			.offset-4 {
				margin-left: 33.33333%
			}
			
			.offset-5 {
				margin-left: 41.66667%
			}
			
			.offset-6 {
				margin-left: 50%
			}
			
			.offset-7 {
				margin-left: 58.33333%
			}
			
			.offset-8 {
				margin-left: 66.66667%
			}
			
			.offset-9 {
				margin-left: 75%
			}
			
			.offset-10 {
				margin-left: 83.33333%
			}
			
			.offset-11 {
				margin-left: 91.66667%
			}
			
			@media (min-width:576px) {
				.col-sm {
					flex-basis: 0;
					flex-grow: 1;
					max-width: 100%
				}
				.col-sm-auto {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%
				}
				.col-sm-1 {
					flex: 0 0 8.33333%;
					max-width: 8.33333%
				}
				.col-sm-2 {
					flex: 0 0 16.66667%;
					max-width: 16.66667%
				}
				.col-sm-3 {
					flex: 0 0 25%;
					max-width: 25%
				}
				.col-sm-4 {
					flex: 0 0 33.33333%;
					max-width: 33.33333%
				}
				.col-sm-5 {
					flex: 0 0 41.66667%;
					max-width: 41.66667%
				}
				.col-sm-6 {
					flex: 0 0 50%;
					max-width: 50%
				}
				.col-sm-7 {
					flex: 0 0 58.33333%;
					max-width: 58.33333%
				}
				.col-sm-8 {
					flex: 0 0 66.66667%;
					max-width: 66.66667%
				}
				.col-sm-9 {
					flex: 0 0 75%;
					max-width: 75%
				}
				.col-sm-10 {
					flex: 0 0 83.33333%;
					max-width: 83.33333%
				}
				.col-sm-11 {
					flex: 0 0 91.66667%;
					max-width: 91.66667%
				}
				.col-sm-12 {
					flex: 0 0 100%;
					max-width: 100%
				}
				.order-sm-first {
					order: -1
				}
				.order-sm-last {
					order: 13
				}
				.order-sm-0 {
					order: 0
				}
				.order-sm-1 {
					order: 1
				}
				.order-sm-2 {
					order: 2
				}
				.order-sm-3 {
					order: 3
				}
				.order-sm-4 {
					order: 4
				}
				.order-sm-5 {
					order: 5
				}
				.order-sm-6 {
					order: 6
				}
				.order-sm-7 {
					order: 7
				}
				.order-sm-8 {
					order: 8
				}
				.order-sm-9 {
					order: 9
				}
				.order-sm-10 {
					order: 10
				}
				.order-sm-11 {
					order: 11
				}
				.order-sm-12 {
					order: 12
				}
				.offset-sm-0 {
					margin-left: 0
				}
				.offset-sm-1 {
					margin-left: 8.33333%
				}
				.offset-sm-2 {
					margin-left: 16.66667%
				}
				.offset-sm-3 {
					margin-left: 25%
				}
				.offset-sm-4 {
					margin-left: 33.33333%
				}
				.offset-sm-5 {
					margin-left: 41.66667%
				}
				.offset-sm-6 {
					margin-left: 50%
				}
				.offset-sm-7 {
					margin-left: 58.33333%
				}
				.offset-sm-8 {
					margin-left: 66.66667%
				}
				.offset-sm-9 {
					margin-left: 75%
				}
				.offset-sm-10 {
					margin-left: 83.33333%
				}
				.offset-sm-11 {
					margin-left: 91.66667%
				}
			}
			
			@media (min-width:768px) {
				.col-md {
					flex-basis: 0;
					flex-grow: 1;
					max-width: 100%
				}
				.col-md-auto {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%
				}
				.col-md-1 {
					flex: 0 0 8.33333%;
					max-width: 8.33333%
				}
				.col-md-2 {
					flex: 0 0 16.66667%;
					max-width: 16.66667%
				}
				.col-md-3 {
					flex: 0 0 25%;
					max-width: 25%
				}
				.col-md-4 {
					flex: 0 0 33.33333%;
					max-width: 33.33333%
				}
				.col-md-5 {
					flex: 0 0 41.66667%;
					max-width: 41.66667%
				}
				.col-md-6 {
					flex: 0 0 50%;
					max-width: 50%
				}
				.col-md-7 {
					flex: 0 0 58.33333%;
					max-width: 58.33333%
				}
				.col-md-8 {
					flex: 0 0 66.66667%;
					max-width: 66.66667%
				}
				.col-md-9 {
					flex: 0 0 75%;
					max-width: 75%
				}
				.col-md-10 {
					flex: 0 0 83.33333%;
					max-width: 83.33333%
				}
				.col-md-11 {
					flex: 0 0 91.66667%;
					max-width: 91.66667%
				}
				.col-md-12 {
					flex: 0 0 100%;
					max-width: 100%
				}
				.order-md-first {
					order: -1
				}
				.order-md-last {
					order: 13
				}
				.order-md-0 {
					order: 0
				}
				.order-md-1 {
					order: 1
				}
				.order-md-2 {
					order: 2
				}
				.order-md-3 {
					order: 3
				}
				.order-md-4 {
					order: 4
				}
				.order-md-5 {
					order: 5
				}
				.order-md-6 {
					order: 6
				}
				.order-md-7 {
					order: 7
				}
				.order-md-8 {
					order: 8
				}
				.order-md-9 {
					order: 9
				}
				.order-md-10 {
					order: 10
				}
				.order-md-11 {
					order: 11
				}
				.order-md-12 {
					order: 12
				}
				.offset-md-0 {
					margin-left: 0
				}
				.offset-md-1 {
					margin-left: 8.33333%
				}
				.offset-md-2 {
					margin-left: 16.66667%
				}
				.offset-md-3 {
					margin-left: 25%
				}
				.offset-md-4 {
					margin-left: 33.33333%
				}
				.offset-md-5 {
					margin-left: 41.66667%
				}
				.offset-md-6 {
					margin-left: 50%
				}
				.offset-md-7 {
					margin-left: 58.33333%
				}
				.offset-md-8 {
					margin-left: 66.66667%
				}
				.offset-md-9 {
					margin-left: 75%
				}
				.offset-md-10 {
					margin-left: 83.33333%
				}
				.offset-md-11 {
					margin-left: 91.66667%
				}
			}
			
			@media (min-width:992px) {
				.col-lg {
					flex-basis: 0;
					flex-grow: 1;
					max-width: 100%
				}
				.col-lg-auto {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%
				}
				.col-lg-1 {
					flex: 0 0 8.33333%;
					max-width: 8.33333%
				}
				.col-lg-2 {
					flex: 0 0 16.66667%;
					max-width: 16.66667%
				}
				.col-lg-3 {
					flex: 0 0 25%;
					max-width: 25%
				}
				.col-lg-4 {
					flex: 0 0 33.33333%;
					max-width: 33.33333%
				}
				.col-lg-5 {
					flex: 0 0 41.66667%;
					max-width: 41.66667%
				}
				.col-lg-6 {
					flex: 0 0 50%;
					max-width: 50%
				}
				.col-lg-7 {
					flex: 0 0 58.33333%;
					max-width: 58.33333%
				}
				.col-lg-8 {
					flex: 0 0 66.66667%;
					max-width: 66.66667%
				}
				.col-lg-9 {
					flex: 0 0 75%;
					max-width: 75%
				}
				.col-lg-10 {
					flex: 0 0 83.33333%;
					max-width: 83.33333%
				}
				.col-lg-11 {
					flex: 0 0 91.66667%;
					max-width: 91.66667%
				}
				.col-lg-12 {
					flex: 0 0 100%;
					max-width: 100%
				}
				.order-lg-first {
					order: -1
				}
				.order-lg-last {
					order: 13
				}
				.order-lg-0 {
					order: 0
				}
				.order-lg-1 {
					order: 1
				}
				.order-lg-2 {
					order: 2
				}
				.order-lg-3 {
					order: 3
				}
				.order-lg-4 {
					order: 4
				}
				.order-lg-5 {
					order: 5
				}
				.order-lg-6 {
					order: 6
				}
				.order-lg-7 {
					order: 7
				}
				.order-lg-8 {
					order: 8
				}
				.order-lg-9 {
					order: 9
				}
				.order-lg-10 {
					order: 10
				}
				.order-lg-11 {
					order: 11
				}
				.order-lg-12 {
					order: 12
				}
				.offset-lg-0 {
					margin-left: 0
				}
				.offset-lg-1 {
					margin-left: 8.33333%
				}
				.offset-lg-2 {
					margin-left: 16.66667%
				}
				.offset-lg-3 {
					margin-left: 25%
				}
				.offset-lg-4 {
					margin-left: 33.33333%
				}
				.offset-lg-5 {
					margin-left: 41.66667%
				}
				.offset-lg-6 {
					margin-left: 50%
				}
				.offset-lg-7 {
					margin-left: 58.33333%
				}
				.offset-lg-8 {
					margin-left: 66.66667%
				}
				.offset-lg-9 {
					margin-left: 75%
				}
				.offset-lg-10 {
					margin-left: 83.33333%
				}
				.offset-lg-11 {
					margin-left: 91.66667%
				}
			}
			
			@media (min-width:1200px) {
				.col-xl {
					flex-basis: 0;
					flex-grow: 1;
					max-width: 100%
				}
				.col-xl-auto {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%
				}
				.col-xl-1 {
					flex: 0 0 8.33333%;
					max-width: 8.33333%
				}
				.col-xl-2 {
					flex: 0 0 16.66667%;
					max-width: 16.66667%
				}
				.col-xl-3 {
					flex: 0 0 25%;
					max-width: 25%
				}
				.col-xl-4 {
					flex: 0 0 33.33333%;
					max-width: 33.33333%
				}
				.col-xl-5 {
					flex: 0 0 41.66667%;
					max-width: 41.66667%
				}
				.col-xl-6 {
					flex: 0 0 50%;
					max-width: 50%
				}
				.col-xl-7 {
					flex: 0 0 58.33333%;
					max-width: 58.33333%
				}
				.col-xl-8 {
					flex: 0 0 66.66667%;
					max-width: 66.66667%
				}
				.col-xl-9 {
					flex: 0 0 75%;
					max-width: 75%
				}
				.col-xl-10 {
					flex: 0 0 83.33333%;
					max-width: 83.33333%
				}
				.col-xl-11 {
					flex: 0 0 91.66667%;
					max-width: 91.66667%
				}
				.col-xl-12 {
					flex: 0 0 100%;
					max-width: 100%
				}
				.order-xl-first {
					order: -1
				}
				.order-xl-last {
					order: 13
				}
				.order-xl-0 {
					order: 0
				}
				.order-xl-1 {
					order: 1
				}
				.order-xl-2 {
					order: 2
				}
				.order-xl-3 {
					order: 3
				}
				.order-xl-4 {
					order: 4
				}
				.order-xl-5 {
					order: 5
				}
				.order-xl-6 {
					order: 6
				}
				.order-xl-7 {
					order: 7
				}
				.order-xl-8 {
					order: 8
				}
				.order-xl-9 {
					order: 9
				}
				.order-xl-10 {
					order: 10
				}
				.order-xl-11 {
					order: 11
				}
				.order-xl-12 {
					order: 12
				}
				.offset-xl-0 {
					margin-left: 0
				}
				.offset-xl-1 {
					margin-left: 8.33333%
				}
				.offset-xl-2 {
					margin-left: 16.66667%
				}
				.offset-xl-3 {
					margin-left: 25%
				}
				.offset-xl-4 {
					margin-left: 33.33333%
				}
				.offset-xl-5 {
					margin-left: 41.66667%
				}
				.offset-xl-6 {
					margin-left: 50%
				}
				.offset-xl-7 {
					margin-left: 58.33333%
				}
				.offset-xl-8 {
					margin-left: 66.66667%
				}
				.offset-xl-9 {
					margin-left: 75%
				}
				.offset-xl-10 {
					margin-left: 83.33333%
				}
				.offset-xl-11 {
					margin-left: 91.66667%
				}
			}
			
			.error-field {
				-webkit-animation-name: shake;
				animation-name: shake;
				-webkit-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both
			}
			
			.error-field input,
			.error-field textarea {
				border-color: #a94442!important;
				color: #a94442!important
			}
			
			.field-error {
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 700;
				position: absolute;
				top: -20px;
				right: 10px
			}
			
			.field-error:after {
				top: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-top-color: #ebcccc;
				border-width: 5px;
				margin-left: -5px
			}
			
			.spinner {
				margin: 0 auto;
				width: 70px;
				text-align: center
			}
			
			.spinner>div {
				width: 12px;
				height: 12px;
				background-color: hsla(0, 0%, 100%, .5);
				margin: 0 2px;
				border-radius: 100%;
				display: inline-block;
				-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
				animation: sk-bouncedelay 1.4s infinite ease-in-out both
			}
			
			.spinner .bounce1 {
				-webkit-animation-delay: -.32s;
				animation-delay: -.32s
			}
			
			.spinner .bounce2 {
				-webkit-animation-delay: -.16s;
				animation-delay: -.16s
			}
			
			@-webkit-keyframes sk-bouncedelay {
				0%,
				80%,
				to {
					-webkit-transform: scale(0)
				}
				40% {
					-webkit-transform: scale(1)
				}
			}
			
			@keyframes sk-bouncedelay {
				0%,
				80%,
				to {
					-webkit-transform: scale(0);
					transform: scale(0)
				}
				40% {
					-webkit-transform: scale(1);
					transform: scale(1)
				}
			}
			
			@-webkit-keyframes shake {
				0%,
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0)
				}
				10%,
				30%,
				50%,
				70%,
				90% {
					-webkit-transform: translate3d(-10px, 0, 0);
					transform: translate3d(-10px, 0, 0)
				}
				20%,
				40%,
				60%,
				80% {
					-webkit-transform: translate3d(10px, 0, 0);
					transform: translate3d(10px, 0, 0)
				}
			}
			
			@keyframes shake {
				0%,
				to {
					-webkit-transform: translateZ(0);
					transform: translateZ(0)
				}
				10%,
				30%,
				50%,
				70%,
				90% {
					-webkit-transform: translate3d(-10px, 0, 0);
					transform: translate3d(-10px, 0, 0)
				}
				20%,
				40%,
				60%,
				80% {
					-webkit-transform: translate3d(10px, 0, 0);
					transform: translate3d(10px, 0, 0)
				}
			}
			
			@media only screen and (max-width:480px) {
				.hide-mobile {
					display: none!important
				}
			}
			
			@media only screen and (min-width:481px) {
				.hide-desktop {
					display: none!important
				}
			}
		</style>


	</head>

	<body>

		<div id="u_body" class="u_body" style="min-height: 100vh; background-color: #ffffff; font-family: arial,helvetica,sans-serif;">

			<div id="u_row_1" class="u_row" style=" padding: 0px;">
				<div style="max-width: 500px;" class="container">
					<div class="row">

						<div id="u_column_1" class="col-md-12 u_column">
							<div style="padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">

								<div id="u_content_text_1" class="u_content_text" style="overflow-wrap: break-word;padding: 10px;">

									<div class="v-text-align" style="color: #000000; line-height: 140%; text-align: left; word-wrap: break-word;">
										<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 14px; line-height: 19.6px;">Content will appear here</span></p>
									</div>

								</div>

							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

	</body>

</html>`;

export const defaultEmailJSONContent = {
	counters: {
		u_column: 3,
		u_row: 3,
		u_content_text: 1,
	},
	body: {
		id: "7QeP6pDLYx",
		rows: [
			{
				id: "q6JBTSqF_c",
				cells: [1],
				columns: [],
				values: {
					displayCondition: null,
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: "no-repeat",
						size: "custom",
						position: "center",
					},
					padding: "0px",
					anchor: "",
					hideDesktop: false,
					_meta: {
						htmlID: "u_row_2",
						htmlClassNames: "u_row",
					},
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
					hideable: true,
				},
			},
			{
				id: "tBmH17oRWI",
				cells: [1],
				columns: [
					{
						id: "k7L9oANYe5",
						contents: [],
						values: {
							backgroundColor: "",
							padding: "0px",
							border: {},
							borderRadius: "0px",
							_meta: {
								htmlID: "u_column_3",
								htmlClassNames: "u_column",
							},
						},
					},
				],
				values: {
					displayCondition: null,
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: "no-repeat",
						size: "custom",
						position: "center",
					},
					padding: "0px",
					anchor: "",
					_meta: {
						htmlID: "u_row_3",
						htmlClassNames: "u_row",
					},
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
					hideable: true,
				},
			},
		],
		values: {
			popupPosition: "center",
			popupWidth: "600px",
			popupHeight: "auto",
			borderRadius: "10px",
			contentAlign: "left",
			contentVerticalAlign: "center",
			contentWidth: 900,
			fontFamily: {
				label: "Arial",
				value: "arial,helvetica,sans-serif",
			},
			textColor: "#000000",
			popupBackgroundColor: "#FFFFFF",
			popupBackgroundImage: {
				url: "",
				fullWidth: true,
				repeat: "no-repeat",
				size: "cover",
				position: "center",
			},
			popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
			popupCloseButton_position: "top-right",
			popupCloseButton_backgroundColor: "#DDDDDD",
			popupCloseButton_iconColor: "#000000",
			popupCloseButton_borderRadius: "0px",
			popupCloseButton_margin: "0px",
			popupCloseButton_action: {
				name: "close_popup",
				attrs: {
					onClick:
						"document.querySelector('.u-popup-container').style.display = 'none';",
				},
			},
			backgroundColor: "#fff",
			backgroundImage: {
				url: "",
				fullWidth: true,
				repeat: "no-repeat",
				size: "custom",
				position: "center",
			},
			preheaderText: "",
			linkStyle: {
				body: true,
				linkColor: "#0000ee",
				linkHoverColor: "#0000ee",
				linkUnderline: true,
				linkHoverUnderline: true,
			},
			_meta: {
				htmlID: "u_body",
				htmlClassNames: "u_body",
			},
		},
	},
	schemaVersion: 12,
};

export const defaultContentPageJSON = {
	counters: {
		u_row: 15,
		u_column: 20,
		u_content_html: 1,
		u_content_text: 3,
		u_content_image: 1,
	},
	body: {
		rows: [
			{
				cells: [1],
				columns: [
					{
						contents: [],
						values: {
							_meta: { htmlID: "u_column_17", htmlClassNames: "u_column" },
							border: {},
							padding: "0px",
							backgroundColor: "",
						},
					},
				],
				values: {
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: false,
						center: true,
						cover: false,
					},
					padding: "10px 0px 20px",
					hideDesktop: false,
					hideMobile: false,
					noStackMobile: true,
					_meta: { htmlID: "u_row_12", htmlClassNames: "u_row" },
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
				},
			},
			{
				cells: [1],
				columns: [
					{
						contents: [
							{
								type: "text",
								values: {
									containerPadding: "10px",
									_meta: {
										htmlID: "u_content_text_3",
										htmlClassNames: "u_content_text",
									},
									selectable: false,
									draggable: false,
									duplicatable: false,
									deletable: false,
									color: "#000000",
									textAlign: "left",
									lineHeight: "140%",
									linkStyle: {
										inherit: false,
										linkColor: "#0000ee",
										linkHoverColor: "#0000ee",
										linkUnderline: false,
										linkHoverUnderline: false,
									},
									text: "",
								},
							},
						],
						values: {
							backgroundColor: "",
							padding: "10px 0",
							border: {},
							_meta: { htmlID: "u_column_19", htmlClassNames: "u_column" },
						},
					},
				],
				values: {
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: false,
						center: true,
						cover: false,
					},
					padding: "0px",
					hideDesktop: false,
					hideMobile: false,
					noStackMobile: false,
					_meta: { htmlID: "u_row_14", htmlClassNames: "u_row" },
					selectable: false,
					draggable: false,
					duplicatable: false,
					deletable: false,
				},
			},
		],
		values: {
			backgroundColor: "#ffffff",
			backgroundImage: {
				url: "",
				fullWidth: true,
				repeat: false,
				center: true,
				cover: false,
			},
			contentWidth: "1200px",
			fontFamily: {
				label: "Source Sans Pro",
				value: "Source Sans Pro,sans-serif",
				url: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700",
				defaultFont: true,
			},
			linkStyle: {
				body: true,
				linkColor: "#0000ee",
				linkHoverColor: "#0000ee",
				linkUnderline: true,
				linkHoverUnderline: true,
			},
			_meta: { htmlID: "u_body", htmlClassNames: "u_body" },
		},
	},
};

export const defaultPageJSONContent = {
	counters: {
		u_column: 1,
		u_row: 1,
		u_content_text: 1,
	},
	body: {
		rows: [
			{
				cells: [1],
				columns: [
					{
						contents: [
							{
								type: "text",
								values: {
									containerPadding: "10px",
									_meta: {
										htmlID: "u_content_text_1",
										htmlClassNames: "u_content_text",
									},
									selectable: true,
									draggable: true,
									duplicatable: true,
									deletable: true,
									color: "#000000",
									textAlign: "left",
									lineHeight: "140%",
									linkStyle: {
										inherit: true,
										linkColor: "#0000ee",
										linkHoverColor: "#0000ee",
										linkUnderline: true,
										linkHoverUnderline: true,
									},
									hideDesktop: false,
									hideMobile: false,
									text: '<p style="font-size: 14px; line-height: 140%; text-align: center;"><span style="font-size: 14px; line-height: 19.6px;">Content will appear here</span></p>',
								},
							},
						],
						values: {
							border: {},
							padding: "0px",
							backgroundColor: "",
							_meta: {
								htmlID: "u_column_1",
								htmlClassNames: "u_column",
							},
						},
					},
				],
				values: {
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: false,
						center: true,
						cover: false,
					},
					padding: "0px",
					hideDesktop: false,
					hideMobile: false,
					noStackMobile: false,
					_meta: {
						htmlID: "u_row_1",
						htmlClassNames: "u_row",
					},
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
				},
			},
		],
		values: {
			backgroundColor: "#ffffff",
			backgroundImage: {
				url: "",
				fullWidth: true,
				repeat: false,
				center: true,
				cover: false,
			},
			contentWidth: "500px",
			fontFamily: {
				label: "Arial",
				value: "arial,helvetica,sans-serif",
			},
			linkStyle: {
				body: true,
				linkColor: "#0000ee",
				linkHoverColor: "#0000ee",
				linkUnderline: true,
				linkHoverUnderline: true,
			},
			_meta: {
				htmlID: "u_body",
				htmlClassNames: "u_body",
			},
		},
	},
	schemaVersion: 5,
};

export const defaultFields = [
	"first_name",
	"last_name",
	"start_date",
	"birth_date",
	"email",
	"personal_email",
	"phone",
	"manager_name",
	"hiring_manager_name",
	"hiring_manager_email",
	"team_buddy_email",
	"buddy_name",
];
export const dateFields = ["start_date", "birth_date"];
export const emailFields = ["email", "personal_email"];
export const phoneFields = ["phone"];
export const nameFields = ["last_name", "first_name", "hiring_manager_name"];
export const selectFields = ["slack_id", "time_zone", "manager_id", "buddy_id"];
export const notShownFields = [
	"id",
	"extra_fields",
	"avatar_url",
	"chat_messages",
	"tasks",
	"merge_fields",
	"manager_name",
	"buddy_name",
	"custom_relations",
	"slack_user_name",
	"total_tasks",
	"started_tasks",
	"completed_tasks",
	"overdue_tasks",
];
export const infoFields = [
	"email",
	"personal_email",
	"first_name",
	"last_name",
	"manager_email",
	"manager_phone",
	"journey",
	"meetings",
];

export const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/;
export const nameRegex =
	/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
export const lastNameRegex = /^[a-zA-Z][^\s ]*$/;
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const firstNameRegex =
	/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\] ]{2,}$/;
export const emailPrefixRegex =
	/^[\w'\-,.][^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\] ]{2,}$/;

export const dateFormatDefault = "YYYY/MM/DD";
export const dateMonthFormat = "MMM DD YYYY";
export const syncDateTimeFormat = "YYYY-MM-DD, HH:mm UTC";
export const assetMessage = "message";
export const assetPage = "page";
export const assetTask = "task";
export const assetTemplate = "template";
export const assetTrello = "trello";
export const assetTicketing = "ticketing";
export const assetSlack = "slack";
export const assetMicrosoftTeams = "microsoftTeams";
export const messengerIcons = {
	1: <Envelope />,
	2: <EnvelopeFill />,
	3: <PaperPlane />,
};

export const logoText = "logo";
export const bgImageText = "bgImage";
export const emailLogoText = "emailLogo";
export const publicallyAccessibleAssets = [emailLogoText];

export const defaultColors = {
	solid: ["#941093", "#fb8560", "#408bef", "#6d53dd", "#83e181", "#3bc7ab"],
	gradient: [
		"linear-gradient(219deg, #801f8d, #6f1b7a, #731c7f, #6a1a74 31%, #f0487f)",
		"linear-gradient(296deg, #f54654, #fd8966)",
		"linear-gradient(296deg, #2e4ae5, #5da9ff)",
		"linear-gradient(296deg, #7b75ed, #483baa)",
		"linear-gradient(326deg, #e7ff8e, #58ceb6)",
		"linear-gradient(296deg, #1db8ab, #3eccce)",
	],
};
export const fontColors = [variables.BLACK_COLOR];
export const chatWidgetPositions = ["left", "right"];
export const chatWidgetShapes = ["square", "circle"];
export const defaultChatWidgetColor = "#941093";

export const templateDrawerWidth = 600;

export const portalLanding = "landingPage";
export const portalHome = "homePage";
export const portalCustomize = "customizePortal";
export const profileIconSize = 50;

export const routerRoles = {
	admin: {
		url: "journeys",
		router: userRoutes,
		menu: menus,
		search: "journey_type=journey",
	},
	user: {
		url: "journeys",
		router: userRoutes,
		menu: menus,
		search: "journey_type=journey",
	},
	// admin: {
	// 	url: "dashboard",
	// 	router: userRoutes,
	// 	menu: menus,
	// 	search: "",
	// },
	// user: {
	// 	url: "dashboard",
	// 	router: userRoutes,
	// 	menu: menus,
	// 	search: "",
	// },
	super_admin: {
		url: "templates",
		router: templateRoutes,
		menu: templateMenu,
		search: "template_type=email",
	},
};

export const onBoardUser = "user";
export const onBoardEmployee = "employee";
export const appAdmin = "admin";
export const appSuperAdmin = "super_admin";
export const employeeTask = "employeeTask";
export const employeeActivity = "activityLog";
export const updateEmployee = "employeeUpdate";
export const dateFormatGeneralMeeting = "YYYY/MM/DD HH:mm";

export const contentTypeExcel = [
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	"application/vnd.ms-excel",
];

export const contentTypeCSVText = ["text/csv", "text/plain"];

export const contentTypePDF = "application/pdf";

export const contentTypeZip = "application/zip";

export const tabTask = "task";
export const tabHome = "home";

export const assetsImages = {
	message: assetsMessage,
	page: assetsPage,
	template: assetsTemplate,
	task: assetsTask,
	taskTemplate: assetsTask,
	trello: assetsTask,
	slack: assetsTask,
	microsoftTeams: assetsTask,
	ticketing: assetsTask,
};

export const portalImages = {
	portalPage: assetsPage,
	customizePortal: portalCustomization,
};

export const meetingScheduleTimeMinute = 10;

export const portalPage = "portalPage";

export const settingsUsers = "users";

export const settingsRoles = "rolesAndPermissions";

export const settingsEmail = "emailSettings";

export const settingsDomain = "domainSettings";

export const settingsMerge = "mergeSettings";

export const settingsSlack = "slackSettings";

export const settingsIntegration = "integrationSettings";

export const settingsCustomField = "customFieldSettings";

export const attachmentImage =
	"https://s3.amazonaws.com/development.gosquadsy.com/attach.png";
export const pdfImage =
	"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Noun_Project_PDF_icon_117327_cc.svg/1024px-Noun_Project_PDF_icon_117327_cc.svg.png";
export const textFileImage =
	"https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/File_alt_font_awesome.svg/512px-File_alt_font_awesome.svg.png";
export const htmlFileImage =
	"https://www.shareicon.net/data/512x512/2016/07/26/802238_html_512x512.png";

export const sqFavicon =
	"https://s3.amazonaws.com/development.gosquadsy.com/favicon.ico";

export const customToolVideo =
	"unlayer.registerTool({\n" +
	"  name: 'my_tool',\n" +
	"  label: 'Video',\n" +
	"  icon: 'fa-photo-video',\n" +
	"  supportedDisplayModes: ['web'],\n" +
	"  options: {\n" +
	"    default: {\n" +
	"      title: null,\n" +
	"    },\n" +
	"    text: {\n" +
	"      title: 'Video Link',\n" +
	"      position: 1,\n" +
	"      options: {\n" +
	"        linkKey: {\n" +
	"          label: 'Link',\n" +
	"          defaultValue: '',\n" +
	"          widget: 'text', \n" +
	"        },\n" +
	"      },\n" +
	"    },\n" +
	"    number: {\n" +
	"      title: 'Size',\n" +
	"      position: 1,\n" +
	"      options: {\n" +
	"        height: {\n" +
	"          label: 'Height (px)',\n" +
	"          defaultValue: '300',\n" +
	"          widget: 'text', \n" +
	"        },\n" +
	"        width: {\n" +
	"          label: 'Width (px)',\n" +
	"          defaultValue: '480',\n" +
	"          widget: 'text', \n" +
	"        },\n" +
	"      },\n" +
	"    },\n" +
	"  },\n" +
	"  values: {},\n" +
	"  renderer: {\n" +
	"    Viewer: unlayer.createViewer({\n" +
	"      render(values) {\n" +
	'        return `<div style="width: ${values.width}px; height: ${values.height}px; max-width: 100%;"><img  style="display: ${!values.linkKey ? "block" : "none"}; height: ${values.height}px; width:${values.width}px; max-width: 100%;" src="https://s3.amazonaws.com/development.gosquadsy.com/placeholder.png"/><iframe  style="display: ${values.linkKey ? "block" : "none"}; width:${values.width}px; height:${values.height}px;max-width: 100%;" src="${values.linkKey}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope" allowfullscreen ></iframe></div>`;\n' +
	"      },\n" +
	"    }),\n" +
	"    exporters: {\n" +
	"      web: function (values) {\n" +
	'        return `<div style="width: ${values.width}px; height: ${values.height}px; max-width: 100%;"><iframe style="display: ${values.linkKey ? "block" : "none"}; width:${values.width}px; height:${values.height}px; max-width: 100%;" src=${values.linkKey} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope" allowfullscreen ></iframe><img style="display: ${!values.linkKey ? "block" : "none"}; width:${values.width}px; height:${values.height}px; max-width: 100%;" src="https://s3.amazonaws.com/development.gosquadsy.com/placeholder.png" /></div>`;\n' +
	"      },\n" +
	"    },\n" +
	"    head: {\n" +
	"      css: function (values) {},\n" +
	"      js: function (values) {},\n" +
	"    },\n" +
	"  },\n" +
	"});";

export const timeOut500 = 500;
export const timeOut2000 = 2000;
export const timeOut3000 = 3000;
export const assetsTaskTemplate = "taskTemplate";

export const meetingTemplate = "meetingTemplate";

export const defaultTasks = [
	{
		name: "Task 1",
		due_date: "2020-06-27",
		status: "completed",
	},
	{
		name: "Task 2",
		due_date: "2020-06-27",
		status: "completed",
	},
	{
		name: "Task 3",
		due_date: "2020-06-27",
		status: "completed",
	},
	{
		name: "Task 4",
		due_date: "2020-06-27",
	},
	{
		name: "Task 5",
		due_date: "2020-06-27",
	},
];

export const defaultLocation = {
	lang: -74.0059728,
	lat: 40.7127753,
	name: "New York, NY, USA",
};

export const defaultShape = "circle";
export const defaultPosition = "left";

export const employeeRole = {
	id: "employee_role",
	name: "Employee",
};

export const subDomainText = "{sub_domain}";
export const defaultPermissions = [
	{
		policy_name: "dashboard",
		action_name: "view",
	},
	{
		policy_name: "tasks",
		action_name: "view",
	},
];

export const permissionManage = "manage";
export const permissionView = "view";
export const importText = "employee";
export const journeyText = "journey";
export const journeyTimezoneTooltipMessage =
	"All Journey actions will get triggered as per the timezone of the recipient. In case the timezone details are not available, actions will get triggered based on the fallback timezone";
export const portalText = "page";
export const companyText = "company";
export const meetingText = "meeting";
export const userText = "user";
export const roleText = "role";
export const dashboardText = "dashboard";

export const minutes = [5, 15, 30, 45, 60];

export const employeeTableTabs = {
	in_progress: "inprogress_employees",
	list: "list",
	all_employees: "all_employees",
};
export const addEmployeeFormFields = [
	"first_name",
	"last_name",
	"email",
	"phone",
	"start_date",
	"assign_member",
];
export const reportsDateFormat = "YYYY-MM-DD";
export const idleTime = 30;
export const smsImage = (
	<img src={smsIcon} height={40} width={40} alt={"smsIcon"} />
);
export const slackImage = (
	<img src={slackIcon} height={35} width={35} alt={"slackIcon"} />
);
export const emailImage = (
	<img src={emailIcon} height={40} width={40} alt={"emailIcon"} />
);
export const meetingImage = (
	<img src={meetingIcon} height={45} width={45} alt={"meetingIcon"} />
);
export const taskImage = (
	<img src={taskIcon} height={40} width={40} alt={"task"} />
);
export const microsoftTeamsImage = (
	<img src={microsoftTeamsIcon} height={40} width={40} alt={"task"} />
);
export const journeyImage = (
	<div
		style={{
			background: "rgb(149 214 252)",
			borderRadius: "50%",
			padding: "7px",
		}}
	>
		<img src={journeyIcon} height={18} width={18} alt={"journey"} />
	</div>
);

export const activityImages = {
	"email has been sent": emailImage,
	"email not sent": emailImage,
	"assigned to a email": emailImage,
	"assigned to a journey": journeyImage,
	"assigned to a meeting": meetingImage,
	"assigned to a sms": smsImage,
	"assigned to a task": taskImage,
	"meeting has been updated": meetingImage,
	"removed from a email": emailImage,
	"removed from a meeting": meetingImage,
	"removed from a sms": smsImage,
	"removed from a task": taskImage,
	"removed from journey": journeyImage,
	"task has been completed": taskImage,
	"task has been reopen": taskImage,
	"task has been updated": taskImage,
	"task reminder has been sent": taskImage,
	"journey has been paused": journeyImage,
	"journey has been resumed": journeyImage,
	"sms has been sent": smsImage,
	"sms not sent": smsImage,
	"assigned to a slack message": slackImage,
	"slack message has been sent": slackImage,
	"slack message not sent": slackImage,
	"removed from a slack message": slackImage,
};
export const activityModules = {
	"sms has been sent": "sms_name",
	"email has been sent": "email_name",
	"employee assigned to a meeting": "meeting_name",
	"employee meeting has been updated": "meeting_name",
	"employee removed from a meeting": "meeting_name",
	"employee assigned to a task": "task_name",
	"employee task has been updated": "task_name",
	"employee task has been completed": "task_name",
	"task has been completed": "task_name",
	"employee removed from a task": "task_name",
	"employee task has been reopen": "task_name",
	"employee task reminder has been sent": "task_name",
	"employee assigned to a journey": "journey_name",
	"employee removed from journey": "journey_name",
	"journey has been paused": "journey_name",
	"journey has been resumed": "journey_name",
	"employee assigned to a email": "email_name",
	"email not sent": "email_name",
	"employee removed from a email": "email_name",
	"employee assigned to a sms": "sms_name",
	"sms not sent": "sms_name",
	"employee removed from a sms": "sms_name",
};

export const nonAssignedActivity = [
	"sms has been sent",
	"sms not sent",
	"email not sent",
	"email has been sent",
	"employee logged in",
	"employee logged out",
];

export const sendEmailTrigger = [
	{
		data: "label.invite.send.task",
		value: "task",
	},
	{
		data: "label.invite.send.days",
		value: "days",
	},
];

export const sendEmailMeetingTrigger = [
	{
		data: "label.invite.send.meeting",
		value: "meeting",
	},
	{
		data: "label.journey.invite.suffix",
		value: "days",
	},
];

export const journeyNotificationTypeOptions = [
	{ value: "email", label: <IntlMessages id={"label.email"} /> },
	{ value: "sms", label: <IntlMessages id={"label.sms"} /> },
];

export const defaultSimpleEmailHTMLContent = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html
  xmlns="http://www.w3.org/1999/xhtml"
  xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
>
  <head>
    <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="x-apple-disable-message-reformatting" />
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <!--<![endif]-->
    <title></title>

    <style type="text/css">
      @media only screen and (min-width: 920px) {
        .u-row {
          width: 900px !important;
        }
        .u-row .u-col {
          vertical-align: top;
        }

        .u-row .u-col-100 {
          width: 900px !important;
        }
      }

      @media (max-width: 920px) {
        .u-row-container {
          max-width: 100% !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
        .u-row .u-col {
          min-width: 320px !important;
          max-width: 100% !important;
          display: block !important;
        }
        .u-row {
          width: 100% !important;
        }
        .u-col {
          width: 100% !important;
        }
        .u-col > div {
          margin: 0 auto;
        }
      }
      body {
        margin: 0;
        padding: 0;
      }

      table,
      tr,
      td {
        vertical-align: top;
        border-collapse: collapse;
      }

      p {
        margin: 0;
      }

      .ie-container table,
      .mso-container table {
        table-layout: fixed;
      }

      * {
        line-height: inherit;
      }

      a[x-apple-data-detectors="true"] {
        color: inherit !important;
        text-decoration: none !important;
      }

      table,
      td {
        color: #000000;
      }
    </style>
  </head>

  <body
    class="clean-body u_body"
    style="
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      background-color: #fff;
      color: #000000;
    "
  >
    <!--[if IE]><div class="ie-container"><![endif]-->
    <!--[if mso]><div class="mso-container"><![endif]-->
    <table
      style="
        border-collapse: collapse;
        table-layout: fixed;
        border-spacing: 0;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        vertical-align: top;
        min-width: 320px;
        margin: 0 auto;
        background-color: #fff;
        width: 100%;
      "
      cellpadding="0"
      cellspacing="0"
    >
      <tbody>
        <tr style="vertical-align: top">
          <td
            style="
              word-break: break-word;
              border-collapse: collapse !important;
              vertical-align: top;
            "
          >
            <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color: #fff;"><![endif]-->

            <div
              class="u-row-container"
              style="padding: 0px; background-color: transparent"
            >
              <div
                class="u-row"
                style="
                  margin: 0 auto 0 0;
                  min-width: 320px;
                  max-width: 900px;
                  overflow-wrap: break-word;
                  word-wrap: break-word;
                  word-break: break-word;
                  background-color: transparent;
                "
              >
                <div
                  style="
                    border-collapse: collapse;
                    display: table;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                  "
                >
                  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding: 0px;background-color: transparent;" align="left"><table cellpadding="0" cellspacing="0" border="0" style="width:900px;"><tr style="background-color: transparent;"><![endif]-->

                  <!--[if (mso)|(IE)]><td align="center" width="900" style="width: 900px;padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;" valign="top"><![endif]-->
                  <div
                    class="u-col u-col-100"
                    style="
                      max-width: 320px;
                      min-width: 900px;
                      display: table-cell;
                      vertical-align: top;
                    "
                  >
                    <div
                      style="
                        height: 100%;
                        width: 100% !important;
                        border-radius: 0px;
                        -webkit-border-radius: 0px;
                        -moz-border-radius: 0px;
                      "
                    >
                      <!--[if (!mso)&(!IE)]><!--><div
                        style="
                          box-sizing: border-box;
                          height: 100%;
                          padding: 0px;
                          border-top: 0px solid transparent;
                          border-left: 0px solid transparent;
                          border-right: 0px solid transparent;
                          border-bottom: 0px solid transparent;
                          border-radius: 0px;
                          -webkit-border-radius: 0px;
                          -moz-border-radius: 0px;
                        "
                      ><!--<![endif]-->
                        <table
                          style="font-family: arial, helvetica, sans-serif"
                          role="presentation"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                          border="0"
                        >
                          <tbody>
                            <tr>
                              <td
                                style="
                                  overflow-wrap: break-word;
                                  word-break: break-word;
                                  padding: 0px;
                                  font-family: arial, helvetica, sans-serif;
                                "
                                align="left"
                              >
                                <div
                                  style="
                                    line-height: 140%;
                                    text-align: left;
                                    word-wrap: break-word;
                                  "
                                >
                                  <p style="line-height: 140%">
                                    This is a new Text block. Change the text.
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <!--[if (!mso)&(!IE)]><!-->
                      </div>
                      <!--<![endif]-->
                    </div>
                  </div>
                  <!--[if (mso)|(IE)]></td><![endif]-->
                  <!--[if (mso)|(IE)]></tr></table></td></tr></table><![endif]-->
                </div>
              </div>
            </div>

            <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
          </td>
        </tr>
      </tbody>
    </table>
    <!--[if mso]></div><![endif]-->
    <!--[if IE]></div><![endif]-->
  </body>
</html>`;

export const defaultSimpleEmailJSONContent = {
	counters: {
		u_column: 2,
		u_row: 2,
		u_content_text: 1,
	},
	body: {
		id: "7QeP6pDLYx",
		rows: [
			{
				id: "q6JBTSqF_c",
				cells: [1],
				columns: [
					{
						id: "Rni0GO2RrW",
						contents: [
							{
								id: "D3zC0Yl1gw",
								type: "text",
								values: {
									containerPadding: "0px",
									anchor: "",
									textAlign: "left",
									lineHeight: "140%",
									linkStyle: {
										inherit: true,
										linkColor: "#0000ee",
										linkHoverColor: "#0000ee",
										linkUnderline: true,
										linkHoverUnderline: true,
									},
									displayCondition: null,
									_meta: {
										htmlID: "u_content_text_1",
										htmlClassNames: "u_content_text",
									},
									selectable: true,
									draggable: false,
									duplicatable: false,
									deletable: false,
									hideable: false,
									text: '<p style="line-height: 140%;">This is a new Text block. Change the text.</p>',
								},
							},
						],
						values: {
							backgroundColor: "",
							padding: "0px",
							border: {},
							borderRadius: "0px",
							_meta: {
								htmlID: "u_column_2",
								htmlClassNames: "u_column",
							},
						},
					},
				],
				values: {
					displayCondition: null,
					columns: false,
					backgroundColor: "",
					columnsBackgroundColor: "",
					backgroundImage: {
						url: "",
						fullWidth: true,
						repeat: "no-repeat",
						size: "custom",
						position: "center",
					},
					padding: "0px",
					anchor: "",
					_meta: {
						htmlID: "u_row_2",
						htmlClassNames: "u_row",
					},
					selectable: true,
					draggable: true,
					duplicatable: true,
					deletable: true,
					hideable: true,
				},
			},
		],
		values: {
			popupPosition: "center",
			popupWidth: "600px",
			popupHeight: "auto",
			borderRadius: "10px",
			contentAlign: "left",
			contentVerticalAlign: "center",
			contentWidth: 900,
			fontFamily: {
				label: "Arial",
				value: "arial,helvetica,sans-serif",
			},
			textColor: "#000000",
			popupBackgroundColor: "#FFFFFF",
			popupBackgroundImage: {
				url: "",
				fullWidth: true,
				repeat: "no-repeat",
				size: "cover",
				position: "center",
			},
			popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
			popupCloseButton_position: "top-right",
			popupCloseButton_backgroundColor: "#DDDDDD",
			popupCloseButton_iconColor: "#000000",
			popupCloseButton_borderRadius: "0px",
			popupCloseButton_margin: "0px",
			popupCloseButton_action: {
				name: "close_popup",
				attrs: {
					onClick:
						"document.querySelector('.u-popup-container').style.display = 'none';",
				},
			},
			backgroundColor: "#fff",
			backgroundImage: {
				url: "",
				fullWidth: true,
				repeat: "no-repeat",
				size: "custom",
				position: "center",
			},
			preheaderText: "",
			linkStyle: {
				body: true,
				linkColor: "#0000ee",
				linkHoverColor: "#0000ee",
				linkUnderline: true,
				linkHoverUnderline: true,
			},
			_meta: {
				htmlID: "u_body",
				htmlClassNames: "u_body",
			},
		},
	},
	schemaVersion: 12,
};

const stringConditions = [
	{ name: "LIKE", label: "contains" },
	{ name: "beginsWith", label: "begins with" },
	{ name: "endsWith", label: "ends with" },
	{ name: "doesNotContain", label: "does not contain" },
	{ name: "doesNotBeginWith", label: "does not begin with" },
	{ name: "doesNotEndWith", label: "does not end with" },
	{ name: "null", label: "is null" },
	{ name: "notNull", label: "is not null" },
];

const isNotConditions = [
	{ name: "=", label: "is" },
	{ name: "!=", label: "is not" },
];

const numberConditions = [
	{ name: "=", label: "is" },
	{ name: ">", label: "Greater than" },
	{ name: "<", label: "Smaller than" },
	{ name: "<=", label: "Smaller than equal to" },
	{ name: ">=", label: "Greater than equal to" },
];

export const flexCondFields = [
	{
		name: "start_date",
		label: "Start Date",
		inputType: "date",
		operators: numberConditions,
	},
	{
		name: "time_zone",
		label: "Time Zone",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "buddy_name",
		label: "Buddy Name",
		operators: stringConditions,
	},
	{
		name: "buddy_email",
		label: "Buddy Email",
		operators: stringConditions,
	},
	{
		name: "home_location",
		label: "Home Location",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "work_location",
		label: "Work Location",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "team",
		label: "Team",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "gender",
		label: "Gender",
		operators: isNotConditions,
		valueEditorType: "radio",
		values: [{ name: "", label: "" }],
	},
	{
		name: "ethnicity",
		label: "Ethnicity",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "pay_group",
		label: "Pay Group",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "marital_status",
		label: "Marital Status",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "date_of_birth",
		label: "Date Of Birth",
		inputType: "date",
		operators: numberConditions,
	},
	{
		name: "hire_date",
		label: "Hire Date",
		inputType: "date",
		operators: numberConditions,
	},
	{
		name: "termination_date",
		label: "Termination Date",
		inputType: "date",
		operators: numberConditions,
	},
	{
		name: "employment_status",
		label: "Employment Status",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
	{
		name: "job_title",
		label: "Job Title",
		operators: stringConditions,
	},
	{
		name: "time_off_balance_used",
		label: "time off balance used",
		operators: numberConditions,
		inputType: "number",
	},
	{
		name: "employment_type",
		label: "Employment Type",
		operators: isNotConditions,
		valueEditorType: "select",
		values: [{ name: "", label: "" }],
	},
];
export const DEFAULT_SLACK_BUTTON_NAME = "Mark As Complete";

export const FILTERED_EMPLOYEES_PER_PAGE = 25;
export const FILTERED_EMPLOYEES_SORT_KEY = "name";
export const FILTERED_EMPLOYEES_SORT_ORDER = "asc";

export const INTEGRATION_VALID = "COMPLETE";

export const JOURNEY_TAB = "journey";
export const ANALYTICS_TAB = "analytics";

export const DEFAULT_EVENT_COMPARISON_DATA = {
	loading: false,
	compareKey: null,
	data: [],
};

export const ANALYTICS_STATS_CARD_COLUMNS = [
	{
		title: "Data Point",
		dataIndex: "dataPoint",
		key: "dataPoint",
	},
	{
		title: "Value",
		dataIndex: "value",
		key: "value",
	},
];

export const LINK_ENGAGEMENT_TABLE_COLUMNS = [
	{
		title: "Link",
		dataIndex: "link",
		key: "link",
		width: "50%",
		sorter: (a, b) => {
			return (a.link.toString().trim().toLowerCase() || "") <=
				(b.link.toString().trim().toLowerCase() || "")
				? -1
				: 1;
		},
		render: (text) => {
			return (
				<Link href={text} target="_blank">
					{text}
				</Link>
			);
		},
	},
	{
		title: "Total Clicks",
		dataIndex: "totalClicks",
		key: "totalClicks",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.totalClicks) || 0) <= (Number(b.totalClicks) || 0)
				? -1
				: 1;
		},
		defaultSortOrder: "descend",
	},
	{
		title: "Unique Clicks",
		dataIndex: "uniqueClicks",
		key: "uniqueClicks",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.uniqueClicks) || 0) <= (Number(b.uniqueClicks) || 0)
				? -1
				: 1;
		},
	},
];

export const EVENT_ANALYTICS_COMAPARISON_TABLE_DROPDOWN = [
	{
		label: "Company Display Name",
		value: "company_display_name",
	},
	{
		label: "Company Eins",
		value: "company_eins",
	},
	{
		label: "Company Legal Name",
		value: "company_legal_name",
	},
	{
		label: "Data Source",
		value: "data_source",
	},
	{
		label: "Employment Status",
		value: "employment_status",
	},
	{
		label: "Employment Type",
		value: "employment_type",
	},
	{
		label: "Flsa Status",
		value: "flsa_status",
	},
	{
		label: "Group Name",
		value: "group_name",
	},
	{
		label: "Group Type",
		value: "group_type",
	},
	{
		label: "Home Location City",
		value: "home_location_city",
	},
	{
		label: "Home Location Country",
		value: "home_location_country",
	},
	{
		label: "Home Location State",
		value: "home_location_state",
	},
	{
		label: "Home Location Zip Code",
		value: "home_location_zip_code",
	},
	{ label: "Job Title", value: "job_title" },
	{
		label: "Manager Employment Status",
		value: "manager_employment_status",
	},
	{
		label: "Pay Currency",
		value: "pay_currency",
	},
	{
		label: "Pay Frequency",
		value: "pay_frequency",
	},
	{
		label: "Pay Group Name",
		value: "pay_group_name",
	},
	{
		label: "Pay Period",
		value: "pay_period",
	},
	{ label: "Status", value: "status" },
	{ label: "Team Name", value: "team_name" },
	{ label: "Time Zone", value: "time_zone" },
	{
		label: "Work Location City",
		value: "work_location_city",
	},
	{
		label: "Work Location Country",
		value: "work_location_country",
	},
	{
		label: "Work Location Name",
		value: "work_location_name",
	},
	{
		label: "Work Location Phone Number",
		value: "work_location_phone_number",
	},
	{
		label: "Work Location State",
		value: "work_location_state",
	},
	{
		label: "Work Location Street 1",
		value: "work_location_street_1",
	},
	{
		label: "Work Location Street 2",
		value: "work_location_street_2",
	},
	{
		label: "Work Location Zip Code",
		value: "work_location_zip_code",
	},
];

export const EVENT_ANALYTICS_COMAPARISON_TABLE_COLUMNS = [
	{
		title: "Comparison Field",
		dataIndex: "comparisonField",
		key: "comparisonField",
		width: "40%",
		sorter: (a, b) => {
			return (a.comparisonField.toString().trim().toLowerCase() || "") <=
				(b.comparisonField.toString().trim().toLowerCase() || "")
				? -1
				: 1;
		},
	},
	{
		title: "Opens",
		dataIndex: "opens",
		key: "opens",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.opens) || 0) <= (Number(b.opens) || 0) ? -1 : 1;
		},
		defaultSortOrder: "descend",
	},
	{
		title: "Unique Opens",
		dataIndex: "uniqueOpens",
		key: "uniqueOpens",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.uniqueOpens.split(" ")[0]) || 0) <=
				(Number(b.uniqueOpens.split(" ")[0]) || 0)
				? -1
				: 1;
		},
	},
	{
		title: "Clicks",
		dataIndex: "clicks",
		key: "clicks",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.clicks) || 0) <= (Number(b.clicks) || 0) ? -1 : 1;
		},
	},
	{
		title: "Unique Clicks",
		dataIndex: "uniqueClicks",
		key: "uniqueClicks",
		align: "center",
		sorter: (a, b) => {
			return (Number(a.uniqueClicks.split(" ")[0]) || 0) <=
				(Number(b.uniqueClicks.split(" ")[0]) || 0)
				? -1
				: 1;
		},
	},
];

export const DEFAULT_STAKEHOLDERS = ["Employee", "Manager", "Buddy"];

export const DEFAULT_STAKEHOLDERS_EMAIL = [
	"Employee (Work Email)",
	"Employee (Personal Email)",
	"Manager",
	"Buddy",
];
export const DEFAULT_EMPLOYEE_STAKEHOLDER_KEYS = [
	"Employee",
	"Employee (Work Email)",
	"Employee (Personal Email)",
];
export const EMPLOYEE_WORK_EMAIL = "Employee (Work Email)";
export const EMPLOYEE_PERSONAL_EMAIL = "Employee (Personal Email)";

export const SINGLE_EMPLOYEE_SEARCH = "SINGLE";
export const BATCH_EMPLOYEE_SEARCH = "BATCH";

export const TAGGABLE_TYPE_JOURNEY = "Journey"
export const TAGGABLE_TYPE_ALL = "All"
